/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateUnitInput = {
    comment?: string | null | undefined;
    createdBy?: string | null | undefined;
    dmxDefaultGain?: number | null | undefined;
    id: string;
    languageCode?: string | null | undefined;
    mac?: string | null | undefined;
    name?: string | null | undefined;
    networkMac?: string | null | undefined;
    placedAt?: string | null | undefined;
    screenType?: string | null | undefined;
    softlightDefaultGain?: number | null | undefined;
    sshStatus?: string | null | undefined;
    themeGroups?: Record<string, any> | null | undefined;
    themes?: Record<string, any> | null | undefined;
    unitType?: string | null | undefined;
};
export type apiUpdateUnitMutationVariables = {
    input: UpdateUnitInput;
};
export type apiUpdateUnitMutationResponse = {
    readonly updateUnit: {
        readonly unit: {
            readonly id: string;
            readonly unitNumber: number;
            readonly languageCode: string;
            readonly name: string;
            readonly comment: string;
            readonly mac: string;
            readonly placedAt: string;
            readonly screenType: string;
            readonly dmxDefaultGain: number;
            readonly softlightDefaultGain: number;
            readonly updatedAt: unknown;
            readonly updatedBy: string;
            readonly createdAt: unknown;
            readonly createdBy: string;
            readonly themes: Record<string, any>;
            readonly themeGroups: Record<string, any>;
            readonly unitType: string | null;
            readonly sshStatus: string | null;
        };
    };
};
export type apiUpdateUnitMutation = {
    readonly response: apiUpdateUnitMutationResponse;
    readonly variables: apiUpdateUnitMutationVariables;
};



/*
mutation apiUpdateUnitMutation(
  $input: UpdateUnitInput!
) {
  updateUnit(input: $input) {
    unit {
      id
      unitNumber
      languageCode
      name
      comment
      mac
      placedAt
      screenType
      dmxDefaultGain
      softlightDefaultGain
      updatedAt
      updatedBy
      createdAt
      createdBy
      themes
      themeGroups
      unitType
      sshStatus
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUnitPayload",
    "kind": "LinkedField",
    "name": "updateUnit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Unit",
        "kind": "LinkedField",
        "name": "unit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "languageCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mac",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "placedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "screenType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dmxDefaultGain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "softlightDefaultGain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedBy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdBy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "themes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "themeGroups",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sshStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "apiUpdateUnitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "apiUpdateUnitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ca88935a781120aa2f009d57203a321",
    "id": null,
    "metadata": {},
    "name": "apiUpdateUnitMutation",
    "operationKind": "mutation",
    "text": "mutation apiUpdateUnitMutation(\n  $input: UpdateUnitInput!\n) {\n  updateUnit(input: $input) {\n    unit {\n      id\n      unitNumber\n      languageCode\n      name\n      comment\n      mac\n      placedAt\n      screenType\n      dmxDefaultGain\n      softlightDefaultGain\n      updatedAt\n      updatedBy\n      createdAt\n      createdBy\n      themes\n      themeGroups\n      unitType\n      sshStatus\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '49ae4d33d99e0a4672be4ba7ca4768f7';
export default node;
