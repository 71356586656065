import { default as ReactSelect } from 'react-select'
import CreatableSelect from 'react-select/creatable'

export type ValueLabel = { value: string; label: string }

interface ItemSelectProps {
  options: ValueLabel[]
  selectedItemsOption: ValueLabel | undefined
  setSelectedItemsOption: (valueLabels: ValueLabel) => void
  label: string
  enableCreate?: boolean
}

const SelectSingleItem = ({
  options,
  selectedItemsOption,
  setSelectedItemsOption,
  label,
  enableCreate = false,
}: ItemSelectProps) => {
  const Select = enableCreate ? CreatableSelect : ReactSelect

  return (
    <div>
      <p style={{ marginBottom: 8 }}>{label}</p>
      <Select
        value={selectedItemsOption}
        onChange={(newValue) =>
          newValue &&
          setSelectedItemsOption({
            value: newValue.value,
            label: newValue.label,
          })
        }
        options={options}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#8F4F4D',
            primary: 'white',
            primary75: '#444',
            primary50: '#444',
            danger: '#444',
            dangerLight: '#444',
            neutral0: '#333', // background
            neutral5: '#444',
            neutral10: '#CB976C', // selected background
            neutral20: '#999',
            neutral30: '#CB976C',
            neutral40: '#444',
            neutral50: '#777',
            neutral60: '#777',
            neutral70: '#444',
            neutral80: '#fff', // selected text
            neutral90: '#444',
          },
        })}
      />
    </div>
  )
}

export default SelectSingleItem
