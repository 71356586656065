export const getSignedUrlForFiles = async (
  filename: string,
  jwt: string,
): Promise<string | null> => {
  if (!jwt) return null

  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_API_ENDPOINT + '/theme'
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt,
      },
      body: JSON.stringify({ filename }),
    }
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        resolve(result)
      })
      .catch((error) => reject(error))
  })
}

export const getSignedUrlToDownloadFile = async (
  filename: string,
  jwt: string,
): Promise<string | null> => {
  if (!jwt) return null

  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_API_ENDPOINT + '/storage/file-url'
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt,
      },
      body: JSON.stringify({ filename }),
    }
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        resolve(result)
      })
      .catch((error) => reject(error))
  })
}

export const deleteFile = async (
  filename: string,
  jwt: string,
): Promise<string | null> => {
  if (!jwt) return null

  return new Promise((resolve, reject) => {
    const url = `${
      process.env.REACT_APP_API_ENDPOINT
    }/storage/delete/${encodeURIComponent(filename)}`
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt,
      },
    }
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        resolve(result)
      })
      .catch((error) => reject(error))
  })
}

export const deleteImage = async (
  filename: string,
  jwt: string,
): Promise<string | null> => {
  if (!jwt) return null

  return new Promise((resolve, reject) => {
    const url = `${
      process.env.REACT_APP_API_ENDPOINT
    }/storage/delete/image/${encodeURIComponent(filename)}`
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt,
      },
    }
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        resolve(result)
      })
      .catch((error) => reject(error))
  })
}

export const resizeThumbnail = async (
  filename: string,
  jwt: string,
): Promise<string | null> => {
  if (!jwt) return null

  return new Promise((resolve, reject) => {
    const url = `${
      process.env.REACT_APP_API_ENDPOINT
    }/image-processing/resize/${encodeURIComponent(filename)}`

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt,
      },
    }

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        resolve(result)
      })
      .catch((error) => reject(error))
  })
}
