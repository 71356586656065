import { useMemo } from 'react'
import {
  useMutation as useRelayMutation,
  UseMutationConfig as UseRelayMutationConfig,
} from 'react-relay'
import { GraphQLTaggedNode, MutationParameters } from 'relay-runtime'

type UseMutationConfig<T extends MutationParameters> = Omit<
  UseRelayMutationConfig<T>,
  'onError' | 'onCompleted' | 'onUnsubscribe' | 'variables'
>

export const useMutation = <
  T extends MutationParameters & { variables: { input: unknown } },
>(
  mutation: GraphQLTaggedNode,
  config:
    | UseMutationConfig<T>
    | ((input: T['variables']['input']) => UseMutationConfig<T>) = {},
) => {
  const [commit] = useRelayMutation<T>(mutation)

  return useMemo<(input: T['variables']['input']) => Promise<T['response']>>(
    () => (input) =>
      new Promise((resolve, reject) => {
        commit({
          variables: { input },
          onCompleted: resolve,
          onError: reject,
          ...(typeof config === 'function' ? config(input) : config),
        })
      }),
    [commit, config],
  )
}
