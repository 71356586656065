import MaterialTable from 'material-table'

export const ThemeOverADayTable = ({
  data,
  strings,
}: {
  data: Record<string, number[]>
  strings: {
    name: string
    watched: string
  }
}) => {
  if (!data) return null

  const columns = Object.entries(data).map(([name, watchedArray]) => ({
    name,
    watchedArray,
  }))

  const colorscale = [
    '#ffffff08',
    'rgba(253, 239, 179)',
    'rgb(253, 237, 176)',
    'rgb(249, 198, 139)',
    'rgb(244, 159, 109)',
    'rgb(234, 120, 88)',
    'rgb(218, 83, 82)',
    'rgb(191, 54, 91)',
    'rgb(158, 35, 98)',
    'rgb(120, 26, 97)',
    'rgb(83, 22, 84)',
    'rgb(47, 15, 61)',
  ]

  const maxValue = Math.max(...columns.map((d) => Math.max(...d.watchedArray)))
  const amountOfDataPoints = columns[0]?.watchedArray?.length

  return (
    <>
      <MaterialTable
        title=""
        columns={[
          { field: 'name', title: strings.name },
          {
            field: 'watched',
            title: (
              <div
                style={{
                  height: 26,
                  width: '100%',
                  display: 'flex',
                  transform: `translateY(20px)`,
                  marginTop: 6,
                  marginBottom: 12,
                  alignItems: 'flex-end',
                }}
              >
                {[...new Array(amountOfDataPoints)].map((_, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: '100%',
                        height: '100%',
                        paddingRight: 4,
                      }}
                    >
                      {i % 4 === 0 && (
                        <div
                          style={{
                            color: 'white',
                            fontSize: 10,
                          }}
                        >
                          {i < 10 ? `0${i}:00` : `${i}:00`}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ),
            render: (rowData) => (
              <div
                style={{
                  width: '100%',
                  height: 25,
                  borderRadius: '6px',
                  display: 'flex',
                }}
              >
                {rowData.watchedArray.map((watched, i) => {
                  const gradientIndex = Math.min(
                    Math.ceil(
                      (Math.round(watched * 10) / (maxValue * 10)) *
                        (colorscale.length - 1),
                    ),
                    colorscale.length - 1,
                  )

                  return (
                    <div
                      key={i}
                      style={{
                        width: '100%',
                        height: '100%',
                        paddingRight: 2,
                        paddingLeft: 2,
                        position: 'relative',
                      }}
                    >
                      <div
                        key={i}
                        style={{
                          borderRadius: '4px',
                          width: '100%',
                          height: '100%',
                          backgroundColor: colorscale[gradientIndex],
                          marginBottom: 4,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {watched.toFixed(1) !== '0.0' && (
                          <div
                            style={{
                              color: gradientIndex > 5 ? 'white' : '#00000099',
                              fontWeight: 'bold',
                              fontSize: 10,
                            }}
                          >
                            {watched > 10
                              ? Math.round(watched)
                              : watched.toFixed(1)}
                          </div>
                        )}
                      </div>
                      {i % 4 === 0 && (
                        <div
                          style={{
                            borderLeft: '1px solid #ffffff',
                            transform: 'scaleY(1.5) translateX(-0.5px)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            ),
          },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
          sorting: false,
          exportButton: true,
          headerStyle: { backgroundColor: 'transparent' },
          padding: 'dense',
        }}
        style={{ backgroundColor: 'transparent' }}
        data={columns}
      />
    </>
  )
}
