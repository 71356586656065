import graphql from 'babel-plugin-relay/macro'
import { useMutation } from 'hooks/useMutation'
import { apiCreateThemeGroupMutation } from './__generated__/apiCreateThemeGroupMutation.graphql'
import { apiDeleteThemegroupMutation } from './__generated__/apiDeleteThemegroupMutation.graphql'
import { apiUpdateThemeGroupMutation } from './__generated__/apiUpdateThemeGroupMutation.graphql'

export type ThemeGroup = {
  id: string
  name: string
  description: string | null
  themes: Record<string, any>
}

export const useApi = () => {
  const createThemeGroup = useMutation<apiCreateThemeGroupMutation>(
    graphql`
      mutation apiCreateThemeGroupMutation($input: CreateThemeGroupInput!) {
        createThemeGroup(input: $input) {
          themeGroup {
            id
            name
            description
            themes
          }
        }
      }
    `,
    {
      updater: (store) => {
        const root = store.getRoot()
        const themeGroup = store
          .getRootField('createThemeGroup')
          ?.getLinkedRecord('themeGroup')
        const themeGroups = root.getLinkedRecords('themeGroups') as Array<
          typeof themeGroup
        >
        root.setLinkedRecords([themeGroup, ...themeGroups], 'themeGroups')
      },
    },
  )

  const updateThemeGroup = useMutation<apiUpdateThemeGroupMutation>(
    graphql`
      mutation apiUpdateThemeGroupMutation($input: UpdateThemeGroupInput!) {
        updateThemeGroup(input: $input) {
          themeGroup {
            id
            name
            description
            themes
          }
        }
      }
    `,
  )

  const deleteThemeGroup = useMutation<apiDeleteThemegroupMutation>(
    graphql`
      mutation apiDeleteThemegroupMutation($input: DeleteThemeGroupInput!) {
        deleteThemeGroup(input: $input) {
          status
        }
      }
    `,
    (input) => ({
      updater: (store) => {
        const listOfRecords = store.getRoot().getLinkedRecords('themeGroups')
        const newList = listOfRecords?.filter(record => record.getDataID() !== input.id)
        store.getRoot().setLinkedRecords(newList, 'themeGroups')
      }
    }),
  )

  return {
    createThemeGroup,
    updateThemeGroup,
    deleteThemeGroup,
  }
}
