/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CustomerStatisticsQueryVariables = {};
export type CustomerStatisticsQueryResponse = {
    readonly customerStatistics: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly description: string;
        readonly wavecareBoxIds: Record<string, any>;
        readonly pageViews: number;
    }>;
    readonly units: ReadonlyArray<{
        readonly id: string;
        readonly unitNumber: number;
        readonly name: string;
    }>;
};
export type CustomerStatisticsQuery = {
    readonly response: CustomerStatisticsQueryResponse;
    readonly variables: CustomerStatisticsQueryVariables;
};



/*
query CustomerStatisticsQuery {
  customerStatistics {
    id
    name
    description
    wavecareBoxIds
    pageViews
  }
  units {
    id
    unitNumber
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerStatistics",
    "kind": "LinkedField",
    "name": "customerStatistics",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "wavecareBoxIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pageViews",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Unit",
    "kind": "LinkedField",
    "name": "units",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitNumber",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerStatisticsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomerStatisticsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ba6374eadbc2c212367b51fb2293a41a",
    "id": null,
    "metadata": {},
    "name": "CustomerStatisticsQuery",
    "operationKind": "query",
    "text": "query CustomerStatisticsQuery {\n  customerStatistics {\n    id\n    name\n    description\n    wavecareBoxIds\n    pageViews\n  }\n  units {\n    id\n    unitNumber\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = '5f3d07db14c21fa786bc5235827f6b15';
export default node;
