import { FC, useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { StorageItem } from '../Storage'
import ProcessedFileLineV2 from './ProcessedFileLineV2'
import axios from 'axios'
import { useAuth } from 'hooks'
import { formatDateString } from 'helpers/dateHelper'

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #666666',
    backgroundColor: '#444',
    borderRadius: 8,
    display: 'flex',
  },
  content: {
    padding: 10,
    width: '100%',
  },
  bold: {
    minWidth: 140,
  },
  title: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    justifyContent: 'flex-end',
    wordBreak: 'break-all',
  },
  lastModified: {
    fontSize: 12,
    opacity: 0.5,
  },
  type: {
    marginTop: 10,
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  description: {
    color: '#ffffff70',
  },
  thumbnail: {
    display: 'flex',
    minHeight: '100%',
    minWidth: 120,
    maxWidth: 120,
    borderRadius: 4,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'flex-end',
  },
  formats: {
    display: 'flex',
  },
  fullWidth: {
    width: '100%',
  },
}))

export interface MediaObject {
  filename: string
  original?: StorageItem
  thumbnail?: StorageItem
  signedThumbnail?: string | null
  encrypted?: StorageItem
  sound?: StorageItem
  softlight?: StorageItem
}
type Props = {
  item: MediaObject
  onDeleteFileClicked?: () => void
  onUpdateThumbnailClicked?: () => void
  onDownloadClicked?: () => void
  onSelectMediaObject?: (mediaObject: MediaObject) => void
  onFileItemClicked?: (item: typeof keyTypes[number]) => void
}

const keyTypes = [
  'original',
  'thumbnail',
  'sound',
  'softlight',
  'encrypted',
] as const

const FileCardV2: FC<Props> = (p: Props) => {
  const classes = useStyles()
  const [thumbnailUrl, setThumbnailUrl] = useState(p.item.signedThumbnail)
  const { user } = useAuth()

  const resolveThumbnail = async (key: string) => {
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_API_ENDPOINT
      }/storage/thumbnail-url?key=${encodeURIComponent(key)}`,
      {
        headers: {
          Authorization: `Bearer ${await user?.getIdToken()}`,
        },
      },
    )

    setThumbnailUrl(data.url)
  }

  // To avoid spamming with status requests on files where some parts aren't processed
  const NEW_IF_WITHIN = 60 * 60 // 60 min
  const ts = p.item.filename?.split('_')?.[0]
  const isNewFile = !!ts && new Date().getTime() / 1000 < +ts + NEW_IF_WITHIN

  return (
    <div
      className={classes.container}
      style={{ cursor: p.onSelectMediaObject ? 'pointer' : undefined }}
      onClick={() => p.onSelectMediaObject && p.onSelectMediaObject(p.item)}
    >
      <div
        className={classes.thumbnail}
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
      />
      <div className={classes.fullWidth}>
        <div className={classes.content}>
          <div className={classes.title}>{p.item.filename}</div>
          {p.item.original && (
            <p className={classes.lastModified}>
              Last modified {formatDateString(p.item.original?.LastModified)}
            </p>
          )}
        </div>
        {!p.onSelectMediaObject && (
          <div className={classes.formats}>
            {keyTypes.map((type) => (
              <ProcessedFileLineV2
                fileName={p.item.filename}
                key={type + p.item?.filename}
                type={type}
                storageItem={p.item[type]}
                onFinishedUploading={
                  type === 'thumbnail' ? resolveThumbnail : undefined
                }
                newlyAdded={isNewFile}
                onClick={() => p.onFileItemClicked?.(type)}
              />
            ))}
          </div>
        )}
        {p.onDeleteFileClicked && !p.onSelectMediaObject && (
          <Button onClick={p.onDeleteFileClicked} color="primary">
            Delete
          </Button>
        )}
        {p.onUpdateThumbnailClicked && !p.onSelectMediaObject && (
          <Button onClick={p.onUpdateThumbnailClicked} color="secondary">
            Change thumbnail
          </Button>
        )}
        {/* <Button onClick={p.onDownloadClicked} color="secondary">
          Download
        </Button> */}
      </div>
    </div>
  )
}

export default FileCardV2
