import { StorageItem } from '../Storage'
import { makeStyles } from '@material-ui/core'
import ImageIcon from '@mui/icons-material/Image'
import VideoIcon from '@mui/icons-material/OndemandVideo'
import SoundIcon from '@mui/icons-material/LibraryMusic'
import SoftlightIcon from '@mui/icons-material/BlurOn'
import EncryptedIcon from '@mui/icons-material/Lock'
import filesize from 'filesize'
import { useEffect, useState } from 'react'
import axios from 'axios'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: 8,
    border: '1px solid #666666',
    margin: '8px 0',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#ffffff10',
    },
  },
  content: {
    padding: 10,
    width: '100%',
  },
  item: {
    display: 'flex',
  },
  bold: {
    minWidth: 140,
  },
  field: {
    maxWidth: '130px',
    minWidth: '130px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '3px',
  },
  largeField: {
    width: '100%',
  },
  title: {
    height: 80,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textAlign: 'center',
    justifyContent: 'flex-end',
    wordBreak: 'break-all',
  },
  type: {
    marginTop: 10,
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  description: {
    color: '#ffffff70',
  },
  thumbnail: {
    display: 'flex',
    minWidth: 140,
    minHeight: 140,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: 20,
  },
}))

export interface MediaObject {
  filename: string
  original?: StorageItem
  thumbnail?: StorageItem
  signedThumbnail?: string | null
  encrypted?: StorageItem
  sound?: StorageItem
  softlight?: StorageItem
}

export interface ImageObject {
  filename: string
  file: StorageItem
  signedUrl: string
}

export interface ThemesAndImages {
  themes: MediaObject[]
  images: ImageObject[]
}

interface ItemCardProps {
  item: MediaObject | null | undefined
  newFile?: boolean
  processingDone?: () => void
}

type Keys = Pick<
  MediaObject,
  'original' | 'thumbnail' | 'sound' | 'softlight' | 'encrypted'
>

const keyTypes = [
  'original',
  'thumbnail',
  'sound',
  'softlight',
  'encrypted',
] as const

const ItemCardAlternative = ({
  item,
  newFile,
  processingDone,
}: ItemCardProps) => {
  const classes = useStyles()

  const [isUploading, setIsUploading] = useState(newFile)

  const [processingStatus, setProcessingStatus] =
    useState<
      Record<keyof Keys | 'inProgress', string | null | undefined | boolean>
    >()
  const [runCount, setRunCount] = useState(0)

  useEffect(() => {
    if (!item || !isUploading) return

    const t = setTimeout(() => {
      axios
        .get(
          process.env.REACT_APP_VIDEO_API_ENDPOINT +
            '/status?filename=' +
            encodeURIComponent(item.filename),
        )
        .then((res) => {
          setProcessingStatus(res.data)
        })

      setRunCount((prev) => prev + 1)
    }, Math.min(runCount * 250, 30000))

    return () => {
      clearTimeout(t)
    }
  }, [isUploading, item, runCount])

  const states = Object.values(processingStatus ?? {})
  const finishedStates = states.filter((x) =>
    ['Done.', 'Failed to save.', 'Failed to process file.'].includes(
      x as string,
    ),
  )
  const processingIsDone =
    states.length > 0 && finishedStates.length === states.length

  useEffect(() => {
    if (!processingDone) return

    if (processingIsDone) {
      processingDone()
      setIsUploading(false)
    }
  }, [processingIsDone, processingDone])

  if (!item) return null

  const Icon = {
    original: <VideoIcon style={{ fontSize: 18 }} />,
    thumbnail: <ImageIcon style={{ fontSize: 18 }} />,
    sound: <SoundIcon style={{ fontSize: 18 }} />,
    softlight: <SoftlightIcon style={{ fontSize: 18 }} />,
    encrypted: <EncryptedIcon style={{ fontSize: 18 }} />,
  } as const

  const retryProcessingFor = async (
    type: Omit<typeof keyTypes[number], 'original'>,
  ) => {
    await axios.post(
      process.env.REACT_APP_VIDEO_API_ENDPOINT + '/retry-processing/' + type,
      {
        filename: item.filename,
      },
    )

    // Reset the polling status
    setProcessingStatus(undefined)
    setRunCount(0)
    setIsUploading(true)
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.thumbnail}
        style={{ backgroundImage: `url(${item.signedThumbnail})` }}
      />
      <div className={classes.content}>
        {keyTypes.map((type) => {
          const storageItem = item[type]
          const status = processingStatus?.[type] || null
          const badStatus = [
            'Failed to save.',
            'Failed to process file.',
          ].includes(status as any)
          const goodStatus = status === 'Done.'
          const fallbackName =
            type === 'original' && isUploading ? (
              item?.filename
            ) : (
              <b
                style={{
                  color: goodStatus
                    ? 'lightgreen'
                    : badStatus
                    ? 'red'
                    : undefined,
                }}
              >
                {status ? (
                  status
                ) : (
                  <>
                    No {type}.{' '}
                    {!isUploading && (
                      <a
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => retryProcessingFor(type)}
                      >
                        Retry
                      </a>
                    )}
                  </>
                )}
              </b>
            )

          let displayName = storageItem?.Key ?? fallbackName

          if (typeof displayName === 'string') {
            displayName = displayName.substring(displayName.indexOf('/') + 1)
          }

          return (
            <div key={type + item?.filename} className={classes.item}>
              <div className={classes.field}>
                {Icon[type]}
                <b style={{ marginLeft: '1em' }}>
                  {type.slice(0, 1).toLocaleUpperCase() + type.slice(1)}
                </b>
              </div>
              <div className={classes.largeField}>
                <p>
                  {displayName}
                  {storageItem?.Size ? ` (${filesize(storageItem.Size)})` : ''}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ItemCardAlternative
