/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ThemesQueryVariables = {};
export type ThemesQueryResponse = {
    readonly themes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly titleObject: Record<string, any>;
        readonly descriptionObject: Record<string, any>;
        readonly rawVideoUrl: string;
        readonly thumbnailUrl: string;
        readonly signedThumbnailUrl: string | null;
        readonly videoUrl: string;
        readonly softlightUrl: string;
        readonly soundUrl: string;
        readonly dmx: Record<string, any>;
        readonly updatedAt: unknown;
        readonly createdAt: unknown;
        readonly themeType: string | null;
        readonly dmxDefaultGain: number;
        readonly isMediaComplete: boolean | null;
        readonly categories: ReadonlyArray<string>;
        readonly defaultSoftlightGain: number;
        readonly durationInSeconds: number;
        readonly workTitle: string;
    }>;
    readonly categories: ReadonlyArray<string>;
};
export type ThemesQuery = {
    readonly response: ThemesQueryResponse;
    readonly variables: ThemesQueryVariables;
};



/*
query ThemesQuery {
  themes {
    id
    name
    titleObject
    descriptionObject
    rawVideoUrl
    thumbnailUrl
    signedThumbnailUrl
    videoUrl
    softlightUrl
    soundUrl
    dmx
    updatedAt
    createdAt
    themeType
    dmxDefaultGain
    isMediaComplete
    categories
    defaultSoftlightGain
    durationInSeconds
    workTitle
  }
  categories
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categories",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Theme",
    "kind": "LinkedField",
    "name": "themes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "titleObject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "descriptionObject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawVideoUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedThumbnailUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "videoUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "softlightUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "soundUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dmx",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themeType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dmxDefaultGain",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMediaComplete",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultSoftlightGain",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "durationInSeconds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ThemesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ThemesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8af30961019f389ce95fee084703a2fe",
    "id": null,
    "metadata": {},
    "name": "ThemesQuery",
    "operationKind": "query",
    "text": "query ThemesQuery {\n  themes {\n    id\n    name\n    titleObject\n    descriptionObject\n    rawVideoUrl\n    thumbnailUrl\n    signedThumbnailUrl\n    videoUrl\n    softlightUrl\n    soundUrl\n    dmx\n    updatedAt\n    createdAt\n    themeType\n    dmxDefaultGain\n    isMediaComplete\n    categories\n    defaultSoftlightGain\n    durationInSeconds\n    workTitle\n  }\n  categories\n}\n"
  }
};
})();
(node as any).hash = '29472a98e1d348646d56cc41dcfe30d0';
export default node;
