import { makeStyles } from '@material-ui/core'
import { FC } from 'react'

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingBottom: 8,
  },
  container: {
    border: '1px solid #666666',
    backgroundColor: '#444',
    borderRadius: 8,
    display: 'flex',
  },
  thumbnail: {
    display: 'flex',
    minHeight: '100%',
    minWidth: 120,
    maxWidth: 120,
    borderRadius: 4,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'flex-end',
  },
  text: {
    padding: 20,
  },
  name: {
    fontWeight: 'bold',
  },
}))

interface Props {
  name: string
  categories: readonly string[]
  signedThumbnailUrl: string | null
}

const ThemeCard: FC<Props> = (p) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div
          className={classes.thumbnail}
          style={{ backgroundImage: `url(${p.signedThumbnailUrl})` }}
        />
        <div className={classes.text}>
          <div className={classes.name}>{p.name}</div>
          <div>{p.categories.join(', ')}</div>
        </div>
      </div>
    </div>
  )
}

export default ThemeCard
