import { makeStyles } from '@material-ui/core'
import DualListBox from 'react-dual-listbox'

import './ListSelector.css'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 10,
  },
}))

export type Options = {
  value: string
  label: string
}[]

interface ListSelectorProps {
  allOptions: Options
  selected: string[]
  onChange: (v: string[]) => void
}

const ListSelector = ({
  allOptions,
  selected,
  onChange,
}: ListSelectorProps) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DualListBox
        preserveSelectOrder
        showOrderButtons
        options={allOptions}
        selected={selected}
        onChange={onChange}
      />
    </div>
  )
}

export default ListSelector
