/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ThemeGroupsQueryVariables = {};
export type ThemeGroupsQueryResponse = {
    readonly themeGroups: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly description: string;
        readonly themes: Record<string, any>;
    }>;
};
export type ThemeGroupsQuery = {
    readonly response: ThemeGroupsQueryResponse;
    readonly variables: ThemeGroupsQueryVariables;
};



/*
query ThemeGroupsQuery {
  themeGroups {
    id
    name
    description
    themes
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ThemeGroup",
    "kind": "LinkedField",
    "name": "themeGroups",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ThemeGroupsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ThemeGroupsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4ef41798231a61f641b2cc32581bbb55",
    "id": null,
    "metadata": {},
    "name": "ThemeGroupsQuery",
    "operationKind": "query",
    "text": "query ThemeGroupsQuery {\n  themeGroups {\n    id\n    name\n    description\n    themes\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e79c7a15007412d6196873cecaf2bd9f';
export default node;
