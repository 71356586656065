import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import moment from 'moment'
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import {
  PreloadedQuery,
  UseQueryLoaderLoadQueryOptions,
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from 'react-relay'
import {
  HomeStatsQuery,
  HomeStatsQueryVariables,
} from './__generated__/HomeStatsQuery.graphql'
import { HomeThemesQuery } from './__generated__/HomeThemesQuery.graphql'
import { HomeUnitsQuery } from './__generated__/HomeUnitsQuery.graphql'
import Calendar from './components/Calendar'
import SelectItem, { ValueLabel } from './components/SelectItem'
import SetDateIntervalButton from './components/SetDateIntervalButton'
import ThemeWatchOverADay from './components/ThemeWatchOverADay'
import ThemeWatchOverAPeriod from './components/ThemeWatchOverAPeriod'
import UnitsByWeek from './components/UnitsByWeek'

import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
import { max, sum } from 'lodash'

import { exportAsXlsx } from 'helpers/xlsxExportHelper'
import './Home.css'
import SelectSingleItem from './components/SelectSingleItem'
import { ThemeOverADayTable } from './components/ThemeOverADayTable'
import { ThemeOverAPeriodTable } from './components/ThemeOverAPeriodTable'
import { ThemeTable } from './components/ThemeTable'

const useStyles = makeStyles((theme: any) => ({
  page: {
    padding: theme.spacing(2),
    height: '100vh',
    paddingTop: '226px',
    width: '1400px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  item: {
    backgroundColor: '#444',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  filter: {
    backgroundColor: '#444444f8',
    padding: theme.spacing(2),
    position: 'fixed',
    width: 'calc(100vw - 270px)',
    zIndex: 1000,
    height: '210px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  },
  pickDate: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
  },
  spacer: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}))

export const homeI18nString = {
  en: {
    'Total amount of units': 'Total amount of units',
    Units: 'Units',
    'filtered by theme': 'filtered by theme',
    'and unit': 'and unit',
    'Number of themes watched': 'Number of themes watched',
    'Themes watched': 'Themes watched',
    'Hours watched': 'Hours watched',
    Hours: 'Hours',
    'Theme distribution (hours)': 'Theme distribution (hours)',
    watched: 'watched',
    'Hours watched over a day': 'Hours watched over a day',
    'Hours watched over a period': 'Hours watched over a period',
    'Start date': 'Start date',
    'First entry': 'First entry',
    'End date': 'End date',
    'Included units': 'Included units',
    'Included themes': 'Included themes',
    'Total hours watched (over selected period)':
      'Total hours watched (over selected period)',
    'View time per theme (accumulated over selected period)':
      'View time per theme (accumulated over selected period)',
    'Hours watched over a day (accumulated over selected period - per theme)':
      'Hours watched over a day (accumulated over selected period - per theme)',
    'Hours watched over a day (accumulated over selected period - total)':
      'Hours watched over a day (accumulated over selected period - total)',
    'Hours watched over a period (per theme)':
      'Hours watched over a period (per theme)',
    'Hours watched over a period (accumulated)':
      'Hours watched over a period (accumulated)',
    Total: 'Total',
    'Theme name': 'Theme name',
  },
  fr: {
    'Total amount of units': 'Nombre total d’unités',
    Units: 'Unités',
    'filtered by theme': 'filtrés par thème',
    'and unit': 'et unité',
    'Number of themes watched': 'Nombre de thèmes visionnés',
    'Themes watched': 'Thèmes visionnés',
    'Hours watched': 'Nombre d’heures visionnées',
    Hours: 'heures',
    'Theme distribution (hours)': 'Répartition des themes (heures)',
    watched: 'visionnage',
    'Hours watched over a day': 'Nombre d’heures visionnées par jour',
    'Hours watched over a period': 'Nombre d’heures visionnées sur une période',
    'Start date': 'Date de début',
    'First entry': 'Première entrée',
    'End date': 'Date de fin',
    'Included units': 'Unités incluses',
    'Included themes': 'Thèmes inclus',
    'Total hours watched (over selected period)':
      'Nombre total d’heures visionnées (sur la période sélectionnée)',
    'View time per theme (accumulated over selected period)':
      'Temps de visionnage par thème (accumulé sur la période sélectionnée)',
    'Hours watched over a day (accumulated over selected period - per theme)':
      'Nombre d’heures visionnées par jour (accumulé sur la période sélectionnée - par thème)',
    'Hours watched over a day (accumulated over selected period - total)':
      'Nombre d’heures visionnées par jour (accumulé sur la période sélectionnée - total)',
    'Hours watched over a period (per theme)':
      'Nombre d’heures visionnées sur une période (par thème)',
    'Hours watched over a period (accumulated)':
      'Nombre d’heures visionnées sur une période (accumulé)',
    Total: 'Total',
    'Theme name': 'Nom du thème',
  },
  da: {
    'Total amount of units': 'Totalt antal enheder',
    Units: 'Enheder',
    'filtered by theme': 'filtreret efter tema',
    'and unit': 'og enhed',
    'Number of themes watched': 'Antal sete temaer',
    'Themes watched': 'Temaer set',
    'Hours watched': 'Antal timer set',
    Hours: 'Timer',
    'Theme distribution (hours)': 'Tema fordeling (timer)',
    watched: 'set',
    'Hours watched over a day': 'Antal timer set per dag',
    'Hours watched over a period': 'Antal timer set over en period',
    'Start date': 'Start dato',
    'First entry': 'Første indgang',
    'End date': 'Slut dato',
    'Included units': 'Inkluderet enheder',
    'Included themes': 'Inkluderet temaer',
    'Total hours watched (over selected period)':
      'Total antal timer set (over valgte period)',
    'View time per theme (accumulated over selected period)':
      'Tid set per tema (akkumuleret over valgte period)',
    'Hours watched over a day (accumulated over selected period - per theme)':
      'Antal timer set over en dag (akkumuleret over valgte period - per tema)',
    'Hours watched over a day (accumulated over selected period - total)':
      'Antal timer set over en dag (akkumuleret over valgte period - total)',
    'Hours watched over a period (per theme)':
      'Antal timer set over en period (per tema)',
    'Hours watched over a period (accumulated)':
      'Antal timer set over en period (akkumuleret)',
    Total: 'Total',
    'Theme name': 'Tema navn',
  },
}

const statsQuery = graphql`
  query HomeStatsQuery($input: StatsInput!) {
    getStats(input: $input) {
      totalUnits
      totalUnitsByWeek
      viewTimeByTheme
      themesHoursWatchedOverADay
      themesHoursWatchedOverAPeriod
    }
  }
`

const themesQuery = graphql`
  query HomeThemesQuery {
    themes {
      id
      name
      titleObject
    }
  }
`

const unitsQuery = graphql`
  query HomeUnitsQuery {
    units {
      id
      name
      placedAt
    }
  }
`

const HomeContainer = () => {
  const [statsQueryReference, loadStatsQuery] =
    useQueryLoader<HomeStatsQuery>(statsQuery)
  const [themesQueryReference, loadThemesQuery] =
    useQueryLoader<HomeThemesQuery>(themesQuery)
  const [unitsQueryReference, loadUnitsQuery] =
    useQueryLoader<HomeUnitsQuery>(unitsQuery)

  useEffect(() => {
    loadStatsQuery(
      {
        input: {
          themeIds: [],
          unitIds: [],
          customLightNames: [],
          language: 'en',
          startDate: moment().subtract(3, 'months').toDate(),
          endDate: new Date(),
        },
      },
      { fetchPolicy: 'network-only' },
    )
    loadThemesQuery({}, { fetchPolicy: 'network-only' })
    loadUnitsQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<CircularProgress style={{ margin: 16 }} />}>
      {statsQueryReference && themesQueryReference && unitsQueryReference && (
        <Home
          statsQueryReference={statsQueryReference}
          themesQueryReference={themesQueryReference}
          unitsQueryReference={unitsQueryReference}
          loadStatsQuery={loadStatsQuery}
        />
      )}
    </Suspense>
  )
}

interface HomeProps {
  statsQueryReference: PreloadedQuery<HomeStatsQuery>
  themesQueryReference: PreloadedQuery<HomeThemesQuery>
  unitsQueryReference: PreloadedQuery<HomeUnitsQuery>
  loadStatsQuery: (
    variables: HomeStatsQueryVariables,
    options?: UseQueryLoaderLoadQueryOptions | undefined,
  ) => void
}

const Home = ({
  statsQueryReference,
  themesQueryReference,
  unitsQueryReference,
  loadStatsQuery,
}: HomeProps) => {
  const [selectedUnits, setSelectedUnits] = useState<ValueLabel[]>([])
  const [selectedThemes, setSelectedThemes] = useState<ValueLabel[]>([])
  const [selectedCustomLight, setSelectedCustomLight] = useState<ValueLabel[]>(
    [],
  )
  const [startDate, setStartDate] = useState<Date | null>(
    moment().subtract(3, 'months').startOf('day').toDate(),
  )
  const [endDate, setEndDate] = useState<Date | null>(
    moment().endOf('day').toDate(),
  )
  const [creatingPdf, setCreatingPdf] = useState(false)
  const [creatingXlsx, setCreatingXlsx] = useState(false)
  const [language, setLanguage] = useState<'en' | 'fr' | 'da'>('en')
  const strings = homeI18nString[language]

  const { themes } = usePreloadedQuery(themesQuery, themesQueryReference)
  const { units } = usePreloadedQuery(unitsQuery, unitsQueryReference)
  const {
    getStats: {
      totalUnits,
      totalUnitsByWeek,
      viewTimeByTheme,
      themesHoursWatchedOverADay,
      themesHoursWatchedOverAPeriod,
    },
  } = usePreloadedQuery(statsQuery, statsQueryReference)

  const classes = useStyles()
  const environment = useRelayEnvironment()

  useEffect(() => {
    const variables = {
      input: {
        unitIds: selectedUnits.map((x) => x.value),
        themeIds: selectedThemes.map((x) => x.value),
        customLightNames: selectedCustomLight.map((x) => x.value),
        startDate,
        endDate,
        language,
      },
    }
    // https://relay.dev/docs/next/guided-tour/refetching/refreshing-queries/
    // setIsRefreshing(true)
    fetchQuery(environment, statsQuery, variables).subscribe({
      complete: () => {
        // setIsRefreshing(false)
        loadStatsQuery(variables, { fetchPolicy: 'store-and-network' })
      },
      error: () => {
        // setIsRefreshing(false)
      },
    })
  }, [
    selectedUnits,
    selectedThemes,
    selectedCustomLight,
    startDate,
    endDate,
    language,
  ])

  const getCanvas = (id: string): Promise<HTMLCanvasElement> =>
    new Promise((resolve) => {
      html2canvas(document.getElementById(id) as HTMLElement).then((canvas) => {
        resolve(canvas)
      })
    })

  const toPdf = async () => {
    setCreatingPdf(true)
    const f = await getCanvas('hoursWatched')
    const a = await getCanvas('hoursWatchByTheme')
    const b = await getCanvas('hoursWatchedOverADay')
    const c = await getCanvas('hoursWatchedOverADayTotal')
    const d = await getCanvas('themesWatchedOverAPeriod')
    const e = await getCanvas('themesWatchedOverAPeriodTotal')

    const all = [f, a, b, c, d, e]

    const height = sum(all.map((x) => x.height))
    const width = max(all.map((x) => x.width)) as number

    const pdf = new jsPdf({ unit: 'px', format: [width, height] })

    all.reduce((acc, canvas) => {
      pdf.addImage(
        canvas.toDataURL('image/jpeg'),
        'JPEG',
        0,
        acc,
        canvas.width,
        canvas.height,
      )
      return acc + canvas.height
    }, 0)

    const formatter = new Intl.DateTimeFormat(language, {
      minute: 'numeric',
      hour: 'numeric',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hourCycle: 'h23',
    })

    pdf.save(
      `wavecare report ${formatter.format(new Date())}.pdf`
        .replace(/,/g, '')
        .split(' ')
        .join('_')
        .toLocaleLowerCase(),
    )
    setCreatingPdf(false)
  }

  const dayHours = [...new Array(24)].map(
    (_, i) => i.toString().padStart(2, '0') + '.00',
  )

  const nameFromThemeId = (themeId: string): string => {
    const titleObj =
      themes.find((theme) => theme.id === themeId)?.titleObject || {}
    return titleObj?.[language.toUpperCase()] ?? titleObj?.['EN'] ?? 'Unknown'
  }

  const toXlsx = async () => {
    setCreatingXlsx(true)

    const days = moment(endDate).diff(moment(startDate), 'days')

    const keys = new Set(
      Object.keys(themesHoursWatchedOverAPeriod)
        .map((k) => themesHoursWatchedOverAPeriod[k])
        .map((v) => Object.keys(v))
        .flat(),
    )

    const formatter = new Intl.DateTimeFormat(language, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })

    const dataOverPeriod = days
      ? [...Array(days).keys()].map((day) => {
          const date = moment(startDate).add(day, 'days').toDate()
          const dayKey = Math.floor(date.getTime() / 1000 / 60 / 60 / 24) // Same as log.resolver.ts
          const obj = {
            name: formatter.format(date),
          } as Record<string, any>
          keys.forEach((themeName) => {
            obj[themeName] =
              themesHoursWatchedOverAPeriod[dayKey]?.[themeName] || 0
          })
          return obj
        })
      : []

    const data = [
      {
        title: `${strings['Start date']}: ${
          startDate
            ? formatter.format(startDate)
            : dataOverPeriod[0]
            ? dataOverPeriod[0].name
            : strings['First entry']
        }`,
      },
      {
        title: `${strings['End date']}: ${
          endDate ? formatter.format(endDate) : formatter.format(new Date())
        }`,
      },
      {
        title: `${strings['Included units']}: ${
          selectedUnits.length || totalUnits
        }`,
      },
      {
        title: `${strings['Included themes']}: ${
          selectedThemes.length || Object.keys(viewTimeByTheme).length
        }`,
      },
      {
        title: strings['Total hours watched (over selected period)'],
        header: [strings['Hours']],
        rows: [{ name: 'Total', values: [totalHoursWatched] }],
      },
      {
        title:
          strings['View time per theme (accumulated over selected period)'],
        header: [strings['Hours']],
        rows:
          Object.keys(viewTimeByTheme as Record<string, number[]>).map(
            (themeId) => ({
              name: nameFromThemeId(themeId),
              values: [viewTimeByTheme[themeId] / 60 / 60],
            }),
          ) || [],
      },
      {
        title:
          strings[
            'Hours watched over a day (accumulated over selected period - per theme)'
          ],
        header: dayHours,
        rows:
          Object.keys(
            themesHoursWatchedOverADayTranslated as Record<string, number[]>,
          ).map((themeTitle) => ({
            name: themeTitle,
            values: themesHoursWatchedOverADayTranslated[themeTitle],
          })) || [],
      },
      {
        title:
          strings[
            'Hours watched over a day (accumulated over selected period - total)'
          ],
        header: dayHours,
        rows: [
          {
            name: strings['Total'],
            values: Object.keys(
              themesHoursWatchedOverADayTranslated as Record<string, number[]>,
            ).reduce((acc, themeTitle) => {
              const values = themesHoursWatchedOverADayTranslated[themeTitle]
              return acc.map((x, i) => x + values[i])
            }, new Array(24).fill(0)),
          },
        ],
      },
      {
        title: strings['Hours watched over a period (per theme)'],
        header: dataOverPeriod.map((x) => x.name),
        rows:
          (dataOverPeriod[0] &&
            Object.keys(dataOverPeriod[0])
              .filter((x) => x !== 'name')
              .map((themeId) => ({
                name: nameFromThemeId(themeId),
                values: dataOverPeriod.map((x) => x[themeId]),
              }))) ||
          [],
      },
      {
        title: strings['Hours watched over a period (accumulated)'],
        header: dataOverPeriod.map((x) => x.name),
        rows: [
          {
            name: 'Total',
            values:
              (dataOverPeriod[0] &&
                Object.keys(dataOverPeriod[0])
                  .filter((x) => x !== 'name')
                  .reduce((acc, themeTitle) => {
                    const values = dataOverPeriod.map((x) => x[themeTitle])
                    return acc.map((x, i) => x + values[i])
                  }, new Array(dataOverPeriod.length).fill(0))) ||
              [],
          },
        ],
      },
    ]

    const base64xlsx = exportAsXlsx({ data })

    const xlsxString = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64xlsx}`
    const link = document.createElement('a')
    link.href = xlsxString
    link.download = 'wavecare_box_stats_export.xlsx'

    link.click()
    setCreatingXlsx(false)
  }

  const filteredByUnitAndThemeText = useCallback(
    () =>
      `${strings['filtered by theme']} (${
        selectedThemes.length || Object.keys(viewTimeByTheme).length
      }) ${strings['and unit']} (${selectedUnits.length || totalUnits})`,
    [selectedThemes, selectedUnits, language],
  )

  const filteredViewTimeByTheme = useMemo(
    () =>
      Object.keys(viewTimeByTheme)
        .map((k) => ({
          name: k,
          watched: viewTimeByTheme[k] / 60 / 60,
        }))
        .filter((x) => x.watched && x.name !== 'undefined')
        .sort((a, b) => b.watched - a.watched),
    [viewTimeByTheme],
  )

  const totalHoursWatched = (
    Object.keys(viewTimeByTheme).reduce(
      (acc, k) => viewTimeByTheme[k] + acc,
      0,
    ) /
    60 /
    60
  ).toFixed(1)

  const themesHoursWatchedOverADayTranslated: Record<string, number[]> =
    Object.keys(themesHoursWatchedOverADay as Record<string, number[]>).reduce(
      (acc, themeId) => ({
        ...acc,
        [nameFromThemeId(themeId)]: themesHoursWatchedOverADay[themeId],
      }),
      {},
    )

  const themesHoursWatchedOverAPeriodTranslated: Record<
    string,
    Record<string, number>
  > = Object.keys(themesHoursWatchedOverAPeriod).reduce((acc, time) => {
    return {
      ...acc,
      [time]: Object.keys(
        themesHoursWatchedOverAPeriod[time] as Record<string, number>,
      ).reduce(
        (acc, themeId) => ({
          ...acc,
          [nameFromThemeId(themeId)]:
            themesHoursWatchedOverAPeriod[time][themeId],
        }),
        {},
      ),
    }
  }, {})

  return (
    <div>
      <div className={classes.filter}>
        <div className={classes.pickDate}>
          <Calendar
            label="From (start of day 00.00)"
            date={startDate}
            setDate={(date) =>
              setStartDate(moment(date).startOf('day').toDate())
            }
          />
          <div className={classes.spacer} />
          <Calendar
            label="To (start of day 00.00)"
            date={endDate}
            setDate={(date) => setEndDate(moment(date).startOf('day').toDate())}
          />
          <SetDateIntervalButton
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onExportClicked={toPdf}
            onExportXlsxClicked={toXlsx}
            creatingPdf={creatingPdf}
            creatingXlsx={creatingXlsx}
          />
          <div className={classes.spacer} />
          <SelectSingleItem
            label="Language"
            options={[
              {
                value: 'en',
                label: 'en',
              },
              {
                value: 'da',
                label: 'da',
              },
              {
                value: 'fr',
                label: 'fr',
              },
            ]}
            selectedItemsOption={{
              value: language,
              label: language,
            }}
            setSelectedItemsOption={(option) =>
              setLanguage(option.value as any)
            }
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectItem
              label="Units"
              options={units.map((theme) => ({
                value: theme.id,
                label: `${theme.name} - ${theme.placedAt}`,
              }))}
              selectedItemsOption={selectedUnits}
              setSelectedItemsOption={setSelectedUnits}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectItem
              label="Themes"
              options={themes.map((theme) => ({
                value: theme.id,
                label: theme.name,
              }))}
              selectedItemsOption={selectedThemes}
              setSelectedItemsOption={setSelectedThemes}
            />
          </Grid>
        </Grid>
      </div>
      <div className={`${classes.page} section-to-print`}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={classes.item}>
              <p>{strings['Total amount of units']}</p>
              <h1>
                {totalUnits} {strings['Units']}{' '}
                {selectedUnits.length
                  ? `(${selectedUnits.length} selected)`
                  : ''}
              </h1>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.item}>
              <p>
                {strings['Number of themes watched']} -{' '}
                {filteredByUnitAndThemeText()}
              </p>
              <h1>
                {Object.keys(viewTimeByTheme).length}{' '}
                {strings['Themes watched']}
              </h1>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.item} id="hoursWatched">
              <p>
                {strings['Hours watched']} - {filteredByUnitAndThemeText()}
              </p>
              <h1>
                {totalHoursWatched} {strings['Hours']}
              </h1>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.item} id="hoursWatchByTheme">
              <p>
                {strings['Theme distribution (hours)']} -{' '}
                {filteredByUnitAndThemeText()}
              </p>
              <div style={{ width: '1330px' }}>
                <ThemeTable
                  data={filteredViewTimeByTheme.map((t) => ({
                    ...t,
                    name: nameFromThemeId(t.name), // Name is ID
                  }))}
                  strings={{
                    name: strings['Theme name'],
                    watched: strings['Hours watched'],
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.item} id="hoursWatchedOverADay">
              <div style={{ width: '1330px' }}>
                <p>
                  {`${
                    strings[
                      'Hours watched over a day (accumulated over selected period - per theme)'
                    ]
                  } - ${filteredByUnitAndThemeText()}`}
                </p>
                <ThemeOverADayTable
                  data={themesHoursWatchedOverADayTranslated}
                  strings={{
                    name: strings['Theme name'],
                    watched: strings['Hours watched'],
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.item} id="hoursWatchedOverADayTotal">
              <div style={{ width: '1330px' }}>
                <ThemeWatchOverADay
                  width={1330}
                  title={`${
                    strings[
                      'Hours watched over a day (accumulated over selected period - total)'
                    ]
                  } - ${filteredByUnitAndThemeText()}`}
                  themesHoursWatchedOverADay={
                    themesHoursWatchedOverADayTranslated
                  }
                  combine
                  hideLegend
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.item} id="themesWatchedOverAPeriod">
              <div style={{ width: '1330px' }}>
                <p>
                  {`${
                    strings['Hours watched over a period (per theme)']
                  } - ${filteredByUnitAndThemeText()}`}
                </p>
                <ThemeOverAPeriodTable
                  data={themesHoursWatchedOverAPeriodTranslated ?? {}}
                  strings={{
                    name: strings['Theme name'],
                    watched: strings['Hours watched'],
                  }}
                  language={language}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.item} id="themesWatchedOverAPeriodTotal">
              <div style={{ width: '1330px' }}>
                <ThemeWatchOverAPeriod
                  width={1330}
                  title={`${
                    strings['Hours watched over a period (accumulated)']
                  } - ${filteredByUnitAndThemeText()}`}
                  themesHoursWatchedOverAPeriod={
                    themesHoursWatchedOverAPeriodTranslated
                  }
                  combine
                  hideLegend
                  language={language}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="section-not-to-print">
            <div className={classes.item}>
              <div style={{ width: '1330px' }}>
                <UnitsByWeek width={1330} unitsByWeek={totalUnitsByWeek} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default HomeContainer
