import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { DialogBox, UploadField } from 'components'
import { CSSProperties, Suspense, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'hooks'
import {
  deleteFile,
  deleteImage,
  getSignedUrlForFiles,
  getSignedUrlToDownloadFile,
  resizeThumbnail,
} from './api'
import { MediaObject, ThemesAndImages } from './components/ItemCardAlternative'
import FileCardV2 from './components/FileCardV2'
import { isArray } from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import graphql from 'babel-plugin-relay/macro'
import { StorageKanbanBoardQuery } from './__generated__/StorageKanbanBoardQuery.graphql'
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay'
import { useMutation } from 'hooks/useMutation'
import { StorageKanbanBoardMutation } from './__generated__/StorageKanbanBoardMutation.graphql'
import {
  mapFilenameToStoragePath,
  mapTypeToFilename,
} from 'helpers/mapTypeToFilename'
import { saveAs } from 'file-saver'
import { ActionProps } from 'components/DialogBox'

const useStyles = makeStyles(() => ({
  container: {
    padding: 8,
  },
  kanbanContainer: {
    display: 'flex',
  },
  uploadButton: {
    margin: 4,
  },
  cardContainerWrapper: {
    display: 'flex',
  },
  column: {
    flexDirection: 'column',
    margin: 8,
  },
  dropzone: {
    width: '470px',
    borderRadius: 4,
    padding: 8,
    minHeight: '100%',
    backgroundColor: '#444',
  },
}))

const storageKanbanBoardQuery = graphql`
  query StorageKanbanBoardQuery {
    storageKanban {
      id
      columns
    }
  }
`

const storageKanbanMutation = graphql`
  mutation StorageKanbanBoardMutation($input: UpdateStorageKanbanInput!) {
    updateStorageKanban(input: $input) {
      storageKanban {
        id
        columns
      }
    }
  }
`

export type StorageItem = {
  Key: string
  LastModified: string
  ETag: string
  Size: number
  StorageClass: string
  Owner: {
    ID: string
  }
  type: string
  thumbnailSignedUrl: string
}

type Columns = {
  [key: string]: {
    order: number
    title: string
    filenames: string[]
  }
}

const mapDataToColumns = (
  storageKanban: Columns,
  data: ThemesAndImages,
): Columns => {
  const filenamesInColumns = Object.values(storageKanban)
    .map((column) => column.filenames)
    .flat()
  const filenamesNotInColumns = data.themes
    .map((item) => item.filename)
    .filter((key) => !filenamesInColumns.includes(key))
  const newColumns = JSON.parse(JSON.stringify(storageKanban))
  newColumns.Inbox.filenames = [
    ...new Set([...newColumns.Inbox.filenames, ...filenamesNotInColumns]),
  ]
  return newColumns
}

type Props = {
  onSelectMediaObject?: (mediaObject: MediaObject) => void
}

const StorageKanbanBoardContainer = ({ onSelectMediaObject }: Props) => {
  const [storageKanbanBoardQueryReference, loadStorageKanbanBoardQuery] =
    useQueryLoader<StorageKanbanBoardQuery>(storageKanbanBoardQuery)

  useEffect(() => {
    loadStorageKanbanBoardQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<CircularProgress style={{ margin: 16 }} />}>
      {storageKanbanBoardQueryReference && (
        <StorageKanbanBoard
          onSelectMediaObject={onSelectMediaObject}
          storageKanbanBoardQueryReference={storageKanbanBoardQueryReference}
        />
      )}
    </Suspense>
  )
}

type StorageKanbanBoardProps = {
  storageKanbanBoardQueryReference: PreloadedQuery<
    StorageKanbanBoardQuery,
    Record<string, unknown>
  >
  onSelectMediaObject?: (mediaObject: MediaObject) => void
}

type ActiveFileItem = {
  type: string
  filename: string
}

const StorageKanbanBoard = ({
  storageKanbanBoardQueryReference,
  onSelectMediaObject,
}: StorageKanbanBoardProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const { storageKanban } = usePreloadedQuery(
    storageKanbanBoardQuery,
    storageKanbanBoardQueryReference,
  )
  const updateStorageKanban = useMutation<StorageKanbanBoardMutation>(
    storageKanbanMutation,
  )

  const [columns, setColumns] = useState<Columns>(storageKanban.columns)
  const [overwriteFilename, setOverwriteFilename] = useState('')
  const [reloadImage, setReloadImage] = useState(false)
  const [activeFileItem, setActiveFileItem] = useState<ActiveFileItem | null>(
    null,
  )
  const [isResizingThumbnail, setIsResizingThumbnail] = useState(false)

  useEffect(() => {
    updateStorageKanban({
      id: storageKanban.id,
      columns: columns,
    })
  }, [columns])

  const classes = useStyles()
  const { user, getAuthToken } = useAuth()
  const { isLoading, error, data, refetch } = useQuery<ThemesAndImages>(
    'repoDataAlternative',
    async () =>
      fetch(process.env.REACT_APP_API_ENDPOINT + '/storage/files-alternative', {
        headers: new Headers({
          Authorization: `Bearer ${await user?.getIdToken()}`,
        }),
      }).then((res) => res.json()),
    {
      refetchInterval: 2000,
      refetchOnMount: 'always',
      retry: 3,
    },
  )

  useEffect(() => {
    if (!data) return
    setColumns(mapDataToColumns(storageKanban.columns, data))
  }, [data, setColumns])

  const uploadedFile = () => {
    // setOpen(false)
    // TODO: Might wanna solve this as it's a race condition
    setTimeout(() => {
      if (overwriteFilename) {
        // We only close if the thumbnail is updated
        if (overwriteFilename.endsWith('thumbnail.png')) {
          _resizeThumbnail(overwriteFilename)
        }
        setReloadImage(true)
        setOpen(false)
      }
      refetch
    }, 1000)
  }

  useEffect(() => {
    if (reloadImage) setReloadImage(false)
  }, [reloadImage])

  const getSignedUrl = async (
    filename: string,
    jwt: string,
  ): Promise<string | null> => {
    return await getSignedUrlForFiles(filename, jwt)
  }

  const errStyle: CSSProperties = {
    padding: '5em 2em',
    textAlign: 'center',
    fontSize: '18px',
  }

  const onDeleteFileClicked = async (filename: string) => {
    if (confirm(`Are you sure you want to delete ${filename}`)) {
      try {
        await deleteFile(filename, await getAuthToken())
        setTimeout(() => refetch(), 600)
      } catch (e) {
        alert('Failed to delete file')
      }
    }
  }

  const onUpdateThumbnailClicked = async (filename: string | undefined) => {
    if (!filename) return
    setOverwriteFilename(filename)
    setOpen(true)
    setActiveFileItem(null)
  }

  const downloadFile = async (name?: string) => {
    const filename = mapFilenameToStoragePath(name)
    if (!filename) return
    const signedUrl = await getSignedUrlToDownloadFile(
      filename,
      await getAuthToken(),
    )
    if (!signedUrl) return
    saveAs(signedUrl, filename)
  }

  const onDownloadClicked = async (filename: string) => {
    console.log('filename', filename)
  }

  if (isLoading) return <div style={errStyle}>Loading...</div>
  if (error) return <div style={errStyle}>Error</div>
  if (data !== undefined && !isArray(data.themes)) {
    return <div style={errStyle}>Error fetching files.</div>
  }

  const onDragEnd = (result: any, columns: Columns, setColumns: any) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.filenames]
      const destItems = [...destColumn.filenames]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          filenames: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          filenames: destItems,
        },
      })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.filenames]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          filenames: copiedItems,
        },
      })
    }
  }

  const onFileItemClicked = (
    type: 'original' | 'thumbnail' | 'sound' | 'softlight' | 'encrypted',
    filename: string,
  ) => {
    setActiveFileItem({ type, filename })
  }

  const _resizeThumbnail = async (_filename?: string) => {
    const filename = `thumbnail/${_filename}`
    const jwt = await getAuthToken()
    if (!filename) return
    setIsResizingThumbnail(true)
    try {
      await resizeThumbnail(filename, jwt)
      setIsResizingThumbnail(false)
      alert('Thumbnail resized successfully')
    } catch (e) {
      alert('An error occured while resizing image ' + JSON.stringify(e))
      setIsResizingThumbnail(false)
    }
  }

  if (reloadImage) return null

  return (
    <div className={classes.container}>
      <div className={classes.uploadButton}>
        <Button
          onClick={() => {
            setOverwriteFilename('')
            setOpen(true)
          }}
          variant="outlined"
          color="secondary"
        >
          Upload file
        </Button>
      </div>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        <div className={classes.kanbanContainer}>
          {columns &&
            Object.entries(columns)
              .sort((a, b) => a[1].order - b[1].order)
              .map(([columnId, column]) => {
                return (
                  <Droppable key={columnId} droppableId={columnId}>
                    {(provided) => (
                      <div className={classes.column}>
                        <h1>{column.title}</h1>
                        <div
                          className={classes.dropzone}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {column.filenames?.map((filename, index) => {
                            const item = data?.themes.find(
                              (t) => t.filename === filename,
                            )
                            if (!item) return null
                            return (
                              <Draggable
                                key={item.filename}
                                draggableId={item.filename}
                                index={index}
                                isDragDisabled={!!onSelectMediaObject}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ padding: 8 }}>
                                      <FileCardV2
                                        item={item}
                                        key={item.filename}
                                        onDeleteFileClicked={() =>
                                          onDeleteFileClicked(item.filename)
                                        }
                                        onUpdateThumbnailClicked={() =>
                                          onUpdateThumbnailClicked(
                                            item.filename + '_thumbnail.png',
                                          )
                                        }
                                        onDownloadClicked={() =>
                                          onDownloadClicked(item.filename)
                                        }
                                        onSelectMediaObject={
                                          onSelectMediaObject
                                        }
                                        onFileItemClicked={(t) =>
                                          onFileItemClicked(t, item.filename)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                )
              })}
        </div>
      </DragDropContext>

      <DialogBox
        open={open}
        title={
          overwriteFilename
            ? `Upload image to override ${overwriteFilename}`
            : 'Upload video'
        }
        fields={[
          <UploadField
            key="uploadField"
            getSignedUrl={getSignedUrl}
            title="Drag and drop a new video or image here"
            uploadedFile={uploadedFile}
            overwriteFilename={overwriteFilename}
          />,
        ]}
        actions={[
          {
            onClick: () => setOpen(false),
            title: 'Close',
          },
        ]}
      />

      <DialogBox
        open={activeFileItem !== null}
        title={'Selet an option'}
        fields={[
          <div>
            <p>
              Select an option for {activeFileItem?.filename} (
              {activeFileItem?.type})
            </p>
          </div>,
        ]}
        actions={
          [
            {
              onClick: () => setActiveFileItem(null),
              title: 'Close',
            },
            activeFileItem?.type === 'thumbnail'
              ? {
                  onClick: () =>
                    !isResizingThumbnail &&
                    _resizeThumbnail(
                      mapTypeToFilename(
                        activeFileItem?.filename,
                        activeFileItem?.type,
                      ),
                    ),
                  title: isResizingThumbnail
                    ? 'Resizing to 600px (loading)'
                    : 'Resize to 600px',
                }
              : null,
            {
              onClick: () =>
                downloadFile(
                  mapTypeToFilename(
                    activeFileItem?.filename,
                    activeFileItem?.type,
                  ),
                ),
              title: 'Download file',
            },
            {
              onClick: () =>
                onUpdateThumbnailClicked(
                  mapTypeToFilename(
                    activeFileItem?.filename,
                    activeFileItem?.type,
                  ),
                ),
              title: 'Upload new file',
            },
          ].filter(Boolean) as ActionProps[]
        }
      />
    </div>
  )
}

export default StorageKanbanBoardContainer
