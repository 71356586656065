/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MobileStatsQueryVariables = {};
export type MobileStatsQueryResponse = {
    readonly mobileAppUsers: {
        readonly totalAmount: number;
    };
    readonly accumulatedMobileAppStats: {
        readonly stats: Record<string, any>;
    };
    readonly accumulatedMobileAppQRLocations: {
        readonly statsAccumulated: Record<string, any>;
    };
};
export type MobileStatsQuery = {
    readonly response: MobileStatsQueryResponse;
    readonly variables: MobileStatsQueryVariables;
};



/*
query MobileStatsQuery {
  mobileAppUsers {
    totalAmount
  }
  accumulatedMobileAppStats {
    stats
  }
  accumulatedMobileAppQRLocations {
    statsAccumulated
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserStatsPayload",
    "kind": "LinkedField",
    "name": "mobileAppUsers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalAmount",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AccumulatedMobileAppStatsPayload",
    "kind": "LinkedField",
    "name": "accumulatedMobileAppStats",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stats",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AccumulatedMobileAppQRLocationsPayload",
    "kind": "LinkedField",
    "name": "accumulatedMobileAppQRLocations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "statsAccumulated",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileStatsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MobileStatsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4f5a02bc8535dfae94b46ddfa40705c4",
    "id": null,
    "metadata": {},
    "name": "MobileStatsQuery",
    "operationKind": "query",
    "text": "query MobileStatsQuery {\n  mobileAppUsers {\n    totalAmount\n  }\n  accumulatedMobileAppStats {\n    stats\n  }\n  accumulatedMobileAppQRLocations {\n    statsAccumulated\n  }\n}\n"
  }
};
})();
(node as any).hash = '467f183ac79af158280b1da905f9fde1';
export default node;
