/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CustomerLogStatisticsInput = {
    customerStatisticsId: string;
    endDate?: unknown | null | undefined;
    startDate?: unknown | null | undefined;
    themeIds: Array<string>;
    unitIds: Array<string>;
};
export type StatsCustomerLogStastisticsQueryVariables = {
    input: CustomerLogStatisticsInput;
};
export type StatsCustomerLogStastisticsQueryResponse = {
    readonly getCustomerLogStastistics: {
        readonly totalUnits: number | null;
        readonly viewTimeByTheme: Record<string, any>;
        readonly themesHoursWatchedOverADay: Record<string, any>;
        readonly themesHoursWatchedOverAPeriod: Record<string, any>;
        readonly wavecareBoxes: Record<string, any>;
        readonly themes: Record<string, any>;
        readonly customerStatisticsName: string;
    };
};
export type StatsCustomerLogStastisticsQuery = {
    readonly response: StatsCustomerLogStastisticsQueryResponse;
    readonly variables: StatsCustomerLogStastisticsQueryVariables;
};



/*
query StatsCustomerLogStastisticsQuery(
  $input: CustomerLogStatisticsInput!
) {
  getCustomerLogStastistics(input: $input) {
    totalUnits
    viewTimeByTheme
    themesHoursWatchedOverADay
    themesHoursWatchedOverAPeriod
    wavecareBoxes
    themes
    customerStatisticsName
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CustomerLogStatisticsPayload",
    "kind": "LinkedField",
    "name": "getCustomerLogStastistics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalUnits",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "viewTimeByTheme",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themesHoursWatchedOverADay",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themesHoursWatchedOverAPeriod",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "wavecareBoxes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerStatisticsName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatsCustomerLogStastisticsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatsCustomerLogStastisticsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "488df93c061d05998a6f6ca8913b8fb9",
    "id": null,
    "metadata": {},
    "name": "StatsCustomerLogStastisticsQuery",
    "operationKind": "query",
    "text": "query StatsCustomerLogStastisticsQuery(\n  $input: CustomerLogStatisticsInput!\n) {\n  getCustomerLogStastistics(input: $input) {\n    totalUnits\n    viewTimeByTheme\n    themesHoursWatchedOverADay\n    themesHoursWatchedOverAPeriod\n    wavecareBoxes\n    themes\n    customerStatisticsName\n  }\n}\n"
  }
};
})();
(node as any).hash = '2860a0281a264512a5dab10d5fb60350';
export default node;
