import graphql from 'babel-plugin-relay/macro'
import { useMutation } from 'hooks/useMutation'
import { apiCanDeleteThemeMutation } from './__generated__/apiCanDeleteThemeMutation.graphql'
import { apiCreateThemeMutation } from './__generated__/apiCreateThemeMutation.graphql'
import { apiDeleteThemeAndDependenciesMutation } from './__generated__/apiDeleteThemeAndDependenciesMutation.graphql'
import { apiDeleteThemeMutation } from './__generated__/apiDeleteThemeMutation.graphql'
import { apiUpdateThemeMutation } from './__generated__/apiUpdateThemeMutation.graphql'

export const useApi = () => {
  const createTheme = useMutation<apiCreateThemeMutation>(
    graphql`
      mutation apiCreateThemeMutation($input: CreateThemeInput!) {
        createTheme(input: $input) {
          theme {
            id
            name
            titleObject
            descriptionObject
            rawVideoUrl
            thumbnailUrl
            signedThumbnailUrl
            videoUrl
            softlightUrl
            soundUrl
            dmx
            createdAt
            updatedAt
            themeType
            categories
            isMediaComplete
          }
        }
      }
    `,
    {
      updater: (store) => {
        const root = store.getRoot()
        const theme = store
          .getRootField('createTheme')
          ?.getLinkedRecord('theme')
        const themes = root.getLinkedRecords('themes') as Array<typeof theme>
        const updatedThemes = [theme].concat(themes)
        root.setLinkedRecords(updatedThemes, 'themes')
      },
    },
  )

  const updateTheme = useMutation<apiUpdateThemeMutation>(graphql`
    mutation apiUpdateThemeMutation($input: UpdateThemeInput!) {
      updateTheme(input: $input) {
        theme {
          id
          name
          titleObject
          descriptionObject
          rawVideoUrl
          thumbnailUrl
          signedThumbnailUrl
          videoUrl
          softlightUrl
          soundUrl
          dmx
          createdAt
          updatedAt
          themeType
          categories
          isMediaComplete
        }
      }
    }
  `)

  const canDeleteTheme = useMutation<apiCanDeleteThemeMutation>(
    graphql`
      mutation apiCanDeleteThemeMutation($input: DeleteThemeInput!) {
        canDeleteTheme(input: $input) {
          status
          unitCount
          themeGroupCount
        }
      }
    `,
  )

  const deleteTheme = useMutation<apiDeleteThemeMutation>(
    graphql`
      mutation apiDeleteThemeMutation($input: DeleteThemeInput!) {
        deleteTheme(input: $input) {
          status
          unitCount
          themeGroupCount
        }
      }
    `,
    (input) => ({
      updater: (store) => {
        const listOfRecords = store.getRoot().getLinkedRecords('themes')
        const newList = listOfRecords?.filter(
          (record) => record.getDataID() !== input.id,
        )
        store.getRoot().setLinkedRecords(newList, 'themes')
      },
    }),
  )

  const deleteThemeAndDependencies =
    useMutation<apiDeleteThemeAndDependenciesMutation>(
      graphql`
        mutation apiDeleteThemeAndDependenciesMutation(
          $input: DeleteThemeInput!
        ) {
          deleteThemeAndDependencies(input: $input) {
            status
            unitCount
            themeGroupCount
          }
        }
      `,
      (input) => ({
        updater: (store) => {
          const listOfRecords = store.getRoot().getLinkedRecords('themes')
          const newList = listOfRecords?.filter(
            (record) => record.getDataID() !== input.id,
          )
          store.getRoot().setLinkedRecords(newList, 'themes')
        },
      }),
    )

  return {
    createTheme,
    updateTheme,
    canDeleteTheme,
    deleteTheme,
    deleteThemeAndDependencies,
  }
}

export const getSignedUrlForTheme = async (
  filename: string,
  jwt: string,
): Promise<string | null> => {
  if (!jwt) return null

  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_API_ENDPOINT + '/theme'
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt,
      },
      body: JSON.stringify({ filename }),
    }
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        resolve(result)
      })
      .catch((error) => reject(error))
  })
}
