import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Spacer } from 'components'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { isValidElement } from 'react'
import { LocalizationObject } from '@types'
import SelectItems, { ValueLabel } from 'pages/Home/components/SelectItem'

interface TextFieldProps {
  type: FieldTypeStrings
  isHidden?: boolean
  label: string
  value: string
  onChange: (val: string) => void
  isMultiline?: boolean
}

interface MultiTextFieldProps {
  type: FieldTypeStrings
  isHidden?: boolean
  title: string
  labels: string[]
  values: string[]
  onChange: (val: string, key: string) => void
  isMultiline?: boolean
}

interface MultiNumberFieldProps {
  type: FieldTypeStrings
  isHidden?: boolean
  title: string
  labels: string[]
  values: number[]
  onChange: (val: number, key: string) => void
}

interface NumberFieldProps {
  type: FieldTypeStrings
  isHidden?: boolean
  label: string
  value: number
  onChange: (val: number) => void
}

interface SelectorProps {
  type: FieldTypeStrings
  isHidden?: boolean
  label: string
  options: string[]
  value: string
  onChange: (val: string) => void
}

interface SelectItemsProps {
  type: FieldTypeStrings
  isHidden?: boolean
  label: string
  options: ValueLabel[]
  selectedItemsOption: ValueLabel[] | undefined
  setSelectedItemsOption: (valueLabels: ValueLabel[]) => void
}

export interface ActionProps {
  onClick: () => void
  title: string
}

type FieldTypeStrings =
  | 'textField'
  | 'numberField'
  | 'selectorField'
  | 'selectItems'
  | 'component'
  | 'multiTextField'
  | 'multiNumberField'

type FieldType =
  | TextFieldProps
  | NumberFieldProps
  | SelectorProps
  | React.ReactNode

interface DialogProps {
  title?: string
  open: boolean
  onClose?: () => void
  fields?: FieldType[]
  actions?: ActionProps[]
  disableBackdropClick?: boolean
  size?: string
}

const ItemTextField = ({
  item: { label, value, onChange, isMultiline = false },
}: {
  item: TextFieldProps
}) => (
  <TextField
    margin="dense"
    id={label}
    label={label}
    fullWidth
    multiline={isMultiline}
    variant="standard"
    value={value}
    onChange={(e) => onChange(e.currentTarget.value)}
    style={{ marginBottom: 8 }}
  />
)

const MultiTextField = ({
  item: { type, title, labels, values, onChange, isMultiline = false },
}: {
  item: MultiTextFieldProps
}) => {
  return (
    <div style={{ marginTop: 16 }}>
      {title}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: 16,
        }}
      >
        {labels.map((label, i) => (
          <div key={i} style={{ marginRight: 16, minWidth: 200 }}>
            <ItemTextField
              item={{
                type,
                label,
                isMultiline,
                value: values[i],
                onChange: (v) => onChange(v, label as keyof LocalizationObject),
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const ItemNumberField = ({
  item: { label, value, onChange },
}: {
  item: NumberFieldProps
}) => (
  <TextField
    margin="dense"
    id={label}
    label={label}
    fullWidth
    variant="standard"
    value={value || 0}
    onChange={(e) => onChange(parseInt(e.currentTarget.value))}
    style={{ marginBottom: 8 }}
  />
)

const MultiNumberField = ({
  item: { type, title, labels, values, onChange },
}: {
  item: MultiNumberFieldProps
}) => {
  return (
    <>
      {title}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: 16,
        }}
      >
        {labels.map((label, i) => (
          <div key={i} style={{ marginRight: 16, minWidth: 200 }}>
            <ItemNumberField
              item={{
                type,
                label,
                value: values[i],
                onChange: (v) => onChange(v, label as keyof LocalizationObject),
              }}
            />
          </div>
        ))}
      </div>
    </>
  )
}

const ItemSelector = (
  { item: { label, options, value, onChange } }: { item: SelectorProps },
  key: number,
) => (
  <div>
    <FormControl
      key={key}
      variant="standard"
      style={{
        marginTop: 10,
        marginBottom: 10,
        minWidth: '100%',
      }}
    >
      <InputLabel id={key + ''}>{label}</InputLabel>
      <Select
        labelId={key + ''}
        id={key + ''}
        value={value}
        onChange={(e) => onChange(e.target.value as string)}
        label={label}
      >
        {options.map((value, key) => (
          <MenuItem key={key} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
)

const DialogBox = ({
  title,
  open,
  onClose,
  fields,
  actions,
  disableBackdropClick = false,
  size,
}: DialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) return
        onClose?.()
      }}
      maxWidth={false}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div style={{ width: size ?? '46rem' }}>
          {fields?.map(
            (
              item: TextFieldProps | NumberFieldProps | React.ReactNode,
              key: number,
            ) => {
              if (!item || (item as TextFieldProps | NumberFieldProps).isHidden)
                return null

              const isComponent = isValidElement(item)
              const type = isComponent
                ? 'component'
                : (item as TextFieldProps | NumberFieldProps).type
              if (type === 'textField') {
                return <ItemTextField item={item as TextFieldProps} key={key} />
              } else if (type === 'multiTextField') {
                return (
                  <MultiTextField
                    item={item as MultiTextFieldProps}
                    key={key}
                  />
                )
              } else if (type === 'multiNumberField') {
                return (
                  <MultiNumberField
                    item={item as MultiNumberFieldProps}
                    key={key}
                  />
                )
              } else if (type === 'numberField') {
                return (
                  <ItemNumberField item={item as NumberFieldProps} key={key} />
                )
              } else if (type === 'selectorField') {
                return <ItemSelector item={item as SelectorProps} key={key} />
              } else if (type === 'selectItems') {
                const {
                  options,
                  selectedItemsOption,
                  setSelectedItemsOption,
                  label,
                } = item as SelectItemsProps
                return (
                  <SelectItems
                    options={options}
                    selectedItemsOption={selectedItemsOption}
                    setSelectedItemsOption={setSelectedItemsOption}
                    label={label}
                    key={key}
                    enableCreate
                  />
                )
              } else if (type === 'component') {
                return item
              } else {
                return null
              }
            },
          )}
        </div>
      </DialogContent>
      <Spacer />
      {actions && (
        <DialogActions>
          {actions.map(({ onClick, title }: ActionProps, key: number) => (
            <div
              key={key}
              style={{
                width: title.toLocaleLowerCase().includes('delete')
                  ? '100%'
                  : '',
              }}
            >
              <Button
                color={
                  title.toLocaleLowerCase().includes('delete')
                    ? 'primary'
                    : 'secondary'
                }
                onClick={onClick}
              >
                {title}
              </Button>
            </div>
          ))}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default DialogBox
