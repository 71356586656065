export const dateFormatter = new Intl.DateTimeFormat('da', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
})

export const formatDate = (date: Date): string => {
  return dateFormatter.format(date)
}

export const formatDateString = (date: string): string => {
  return formatDate(new Date(date))
}
