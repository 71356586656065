import { useAuth } from 'hooks'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import {
  CustomColors,
  Home,
  Login,
  MobileStats,
  Releases,
  ThemeGroups,
  Themes,
  Units,
} from 'pages'
import Layout from './Layout'
import StorageKanbanBoard from 'pages/Storage/StorageKanbanBoard'
import MobileApp from 'pages/MobileApp/MobileApp'
import CustomerStatistics from 'pages/CustomerStatistics/CustomerStatistics'
import Stats from 'pages/Stats/Stats'
import SimpleLayout from 'SimpleLayout'

const Routes = () => {
  const { user, signOut } = useAuth()

  const PrivateRoute = (props: any) => {
    const wavecareEmployee = user?.email?.endsWith('@wavecare.com')
    if (user && !wavecareEmployee) {
      alert('This site is only accessible for Wavecare employees')
      signOut()
    }
    return user ? <Route {...props} /> : <Login />
  }

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute exact path="/">
          <Layout activePath="/" pageName="Dashboard">
            <Home />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/units">
          <Layout activePath="/units" pageName="Wavecare Boxes">
            <Units />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/units-mood-light">
          <Layout activePath="/units-mood-light" pageName="Mood Light">
            <Units onlyMoodlight />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/custom-colors">
          <Layout activePath="/custom-colors" pageName="CustomColors">
            <CustomColors />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/themes">
          <Layout activePath="/themes" pageName="Themes">
            <Themes />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/subscription-package">
          <Layout
            activePath="/subscription-package"
            pageName="Subscription packages"
          >
            <ThemeGroups />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/customer-statistics">
          <Layout
            activePath="/customer-statistics"
            pageName="Customer Statistics"
          >
            <CustomerStatistics />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/mobile-app-themes">
          <Layout activePath="/mobile-app-themes" pageName="Mobile App Themes">
            <MobileApp />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/mobile-app-stats">
          <Layout activePath="/mobile-app-stats" pageName="Mobile App Stats">
            <MobileStats />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/releases">
          <Layout activePath="/releases" pageName="Releases">
            <Releases />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/storage">
          <Layout activePath="/storage" pageName="Storage">
            <StorageKanbanBoard />
          </Layout>
        </PrivateRoute>
        <Route path="/stats/:customerStatisticsId">
          <SimpleLayout pageName="Statistics">
            <Stats />
          </SimpleLayout>
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export default Routes
