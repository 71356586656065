import { makeStyles } from '@material-ui/core'
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    paddingTop: '10px',
  },
}))

interface BarChart {
  data?: Record<string, number> | { name: string }[]
  bars?: { color: string; name: string }[]
  width: number
  height?: number
  color?: string
}

const PieChart = ({ data, bars, width, height, color }: BarChart) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <BarChart width={width} height={height ?? 300} data={data as any[]}>
        <CartesianGrid stroke="#555555" />
        <XAxis dataKey="name" stroke="white" />
        <YAxis stroke="white" />
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        {bars?.map((bar) => (
          <Bar dataKey={bar.name} fill={color ?? bar.color} />
        ))}
      </BarChart>
    </div>
  )
}

export default PieChart
