import MaterialTable from 'material-table'

type DataType = {
  name: string
  watched: number
}[]

export const ThemeTable = ({
  data,
  strings,
}: {
  data: DataType
  strings: {
    name: string
    watched: string
  }
}) => {
  if (!data) return null

  const maxValue = Math.max(...data.map((d) => d.watched))

  return (
    <>
      <MaterialTable
        title=""
        columns={[
          { field: 'name', title: strings.name },
          {
            field: 'watched',
            title: strings.watched,
            render: (rowData) =>
              `${Math.round(rowData.watched)}h ${Math.round(
                (rowData.watched % 1) * 60,
              )}m`,
          },
          {
            field: 'watched',
            title: '',
            render: (rowData) => (
              <div
                style={{
                  width: '100%',
                  height: 20,
                  backgroundColor: '#ffffff10',
                  borderRadius: '6px',
                }}
              >
                <div
                  style={{
                    borderRadius: '6px',
                    width: `${(rowData.watched / maxValue) * 100}%`,
                    height: '100%',
                    backgroundColor: '#E09E67',
                  }}
                />
              </div>
            ),
          },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
          sorting: false,
          exportButton: true,
          headerStyle: { backgroundColor: 'transparent' },
        }}
        style={{ backgroundColor: 'transparent' }}
        data={data}
      />
    </>
  )
}
