import graphql from 'babel-plugin-relay/macro'
import { useMutation } from 'hooks/useMutation'
import { apiUpdateCustomerStatisticsMutation } from './__generated__/apiUpdateCustomerStatisticsMutation.graphql'
import { apiDeleteCustomerStatisticsMutation } from './__generated__/apiDeleteCustomerStatisticsMutation.graphql'
import { apiCreateCustomerStatisticsMutation } from './__generated__/apiCreateCustomerStatisticsMutation.graphql'

export type CustomerStatistics = {
  id: string
  name: string
  description: string | null
  wavecareBoxIds: Record<string, any>
}

export const useApi = () => {
  const createCustomerStatistics =
    useMutation<apiCreateCustomerStatisticsMutation>(
      graphql`
        mutation apiCreateCustomerStatisticsMutation(
          $input: CreateCustomerStatisticsInput!
        ) {
          createCustomerStatistics(input: $input) {
            customerStatistics {
              id
              name
              description
              wavecareBoxIds
            }
          }
        }
      `,
      {
        updater: (store) => {
          const root = store.getRoot()
          const customerStatisticsSingle = store
            .getRootField('createCustomerStatistics')
            ?.getLinkedRecord('customerStatistics')
          const customerStatistics = root.getLinkedRecords(
            'customerStatistics',
          ) as Array<typeof customerStatisticsSingle>
          root.setLinkedRecords(
            [customerStatisticsSingle, ...customerStatistics],
            'customerStatistics',
          )
        },
      },
    )

  const updateCustomerStatistics =
    useMutation<apiUpdateCustomerStatisticsMutation>(
      graphql`
        mutation apiUpdateCustomerStatisticsMutation(
          $input: UpdateCustomerStatisticsInput!
        ) {
          updateCustomerStatistics(input: $input) {
            customerStatistics {
              id
              name
              description
              wavecareBoxIds
            }
          }
        }
      `,
    )

  const deleteCustomerStatistics =
    useMutation<apiDeleteCustomerStatisticsMutation>(
      graphql`
        mutation apiDeleteCustomerStatisticsMutation(
          $input: DeleteCustomerStatisticsInput!
        ) {
          deleteCustomerStatistics(input: $input) {
            status
          }
        }
      `,
      (input) => ({
        updater: (store) => {
          const listOfRecords = store
            .getRoot()
            .getLinkedRecords('customerStatisticss')
          const newList = listOfRecords?.filter(
            (record) => record.getDataID() !== input.id,
          )
          store.getRoot().setLinkedRecords(newList, 'customerStatisticss')
        },
      }),
    )

  return {
    createCustomerStatistics,
    updateCustomerStatistics,
    deleteCustomerStatistics,
  }
}
