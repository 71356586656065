import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AirportShuttle from '@mui/icons-material/AirportShuttle'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ExitToApp from '@mui/icons-material/ExitToApp'
import ImageIcon from '@mui/icons-material/Image'
import Inventory2Icon from '@mui/icons-material/Inventory2'
import LightMode from '@mui/icons-material/LightMode'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import PieChartIcon from '@mui/icons-material/PieChart'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import StayCurrentLandscapeIcon from '@mui/icons-material/StayCurrentLandscape'
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait'
import WorkspacesIcon from '@mui/icons-material/Workspaces'

import { useAuth } from 'hooks'
import { NavLink } from 'react-router-dom'

const drawerWidth = 270

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  pageWrapper: {
    padding: 0,
  },
}))

const noNavStyle = { textDecoration: 'none', color: 'white' }

interface LayoutProps {
  children: any
  activePath: string
  pageName: string
}

export default function Layout({
  children,
  activePath,
  pageName,
}: LayoutProps) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const { signOut } = useAuth()
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const MenuItem = ({
    to,
    icon,
    text,
  }: {
    to: string
    icon: JSX.Element
    text: string
  }) => (
    <NavLink to={to} style={noNavStyle}>
      <ListItem button selected={activePath === to}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </NavLink>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {pageName === 'Dashboard' ? 'Wavecare Data Analytics' : pageName}
            {process.env.REACT_APP_DEV_ENV === 'development' ||
            process.env.NODE_ENV === 'development' ? (
              <b> (Staging - use only for testing purposes)</b>
            ) : (
              ''
            )}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MenuItem to="/" icon={<DashboardIcon />} text="Dashboard" />
          <MenuItem
            to="/units"
            icon={<StayCurrentLandscapeIcon />}
            text="Wavecare Boxes"
          />
          <MenuItem
            to="/units-mood-light"
            icon={<StayCurrentPortraitIcon />}
            text="Mood Light"
          />
          <MenuItem
            to="/custom-colors"
            icon={<LightMode />}
            text="Custom Colors"
          />
          <MenuItem to="/themes" icon={<ImageIcon />} text="Themes" />
          <MenuItem
            to="/subscription-package"
            icon={<WorkspacesIcon />}
            text="Subscription Packages"
          />
          <MenuItem
            to="/customer-statistics"
            icon={<QueryStatsIcon />}
            text="Customer Statistics"
          />
          <MenuItem
            to="/mobile-app-themes"
            icon={<PhoneIphoneIcon />}
            text="Mobile App Themes"
          />

          <MenuItem
            to="/mobile-app-stats"
            icon={<PieChartIcon />}
            text="Mobile App Stats"
          />
          <MenuItem to="/releases" icon={<AirportShuttle />} text="Releases" />
          <MenuItem to="/storage" icon={<Inventory2Icon />} text="Storage" />
          <ListItem
            button
            onClick={() =>
              confirm('Are you sure you want to sign out?') && signOut()
            }
          >
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.pageWrapper}>{children}</div>
      </main>
    </div>
  )
}
