import { makeStyles } from '@material-ui/core'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from 'recharts'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    paddingTop: '10px',
  },
}))

interface UnitsByWeekProps {
  unitsByWeek?: readonly number[] | null
  width?: number
}

const UnitsByWeek = ({ unitsByWeek, width }: UnitsByWeekProps) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <p>Units by week</p>
      {unitsByWeek ? (
        <LineChart
          width={width}
          height={300}
          data={unitsByWeek
            .map((n, i) => ({ name: `${i} weeks ago`, count: n }))
            .reverse()}
        >
          <XAxis interval={2} dataKey="name" stroke="white" />
          <YAxis interval={1} stroke="white" />
          <CartesianGrid stroke="#555555" />

          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="count"
            stroke="#E09E67"
            name="Total units"
          />
          {/* <Tooltip /> */}
        </LineChart>
      ) : (
        <p>No data</p>
      )}
    </div>
  )
}

export default UnitsByWeek
