/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type HomeThemesQueryVariables = {};
export type HomeThemesQueryResponse = {
    readonly themes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly titleObject: Record<string, any>;
    }>;
};
export type HomeThemesQuery = {
    readonly response: HomeThemesQueryResponse;
    readonly variables: HomeThemesQueryVariables;
};



/*
query HomeThemesQuery {
  themes {
    id
    name
    titleObject
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Theme",
    "kind": "LinkedField",
    "name": "themes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "titleObject",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeThemesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomeThemesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b337a752557b319b0e23b357abf04305",
    "id": null,
    "metadata": {},
    "name": "HomeThemesQuery",
    "operationKind": "query",
    "text": "query HomeThemesQuery {\n  themes {\n    id\n    name\n    titleObject\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c57539c30006c545bd8eb0c59ab27b80';
export default node;
