/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type StatsInput = {
    customLightNames: Array<string>;
    endDate?: unknown | null | undefined;
    language: string;
    startDate?: unknown | null | undefined;
    themeIds: Array<string>;
    unitIds: Array<string>;
};
export type HomeStatsQueryVariables = {
    input: StatsInput;
};
export type HomeStatsQueryResponse = {
    readonly getStats: {
        readonly totalUnits: number | null;
        readonly totalUnitsByWeek: ReadonlyArray<number> | null;
        readonly viewTimeByTheme: Record<string, any>;
        readonly themesHoursWatchedOverADay: Record<string, any>;
        readonly themesHoursWatchedOverAPeriod: Record<string, any>;
    };
};
export type HomeStatsQuery = {
    readonly response: HomeStatsQueryResponse;
    readonly variables: HomeStatsQueryVariables;
};



/*
query HomeStatsQuery(
  $input: StatsInput!
) {
  getStats(input: $input) {
    totalUnits
    totalUnitsByWeek
    viewTimeByTheme
    themesHoursWatchedOverADay
    themesHoursWatchedOverAPeriod
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StatsPayload",
    "kind": "LinkedField",
    "name": "getStats",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalUnits",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalUnitsByWeek",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "viewTimeByTheme",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themesHoursWatchedOverADay",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themesHoursWatchedOverAPeriod",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeStatsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeStatsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b47c2e68c77f93ba25805387c4dfbc95",
    "id": null,
    "metadata": {},
    "name": "HomeStatsQuery",
    "operationKind": "query",
    "text": "query HomeStatsQuery(\n  $input: StatsInput!\n) {\n  getStats(input: $input) {\n    totalUnits\n    totalUnitsByWeek\n    viewTimeByTheme\n    themesHoursWatchedOverADay\n    themesHoursWatchedOverAPeriod\n  }\n}\n"
  }
};
})();
(node as any).hash = '06e0cf8ecb0588fe21e3bfea6952f91d';
export default node;
