/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateThemeGroupInput = {
    description?: string | null | undefined;
    id: string;
    name?: string | null | undefined;
    themes?: Record<string, any> | null | undefined;
};
export type apiUpdateThemeGroupMutationVariables = {
    input: UpdateThemeGroupInput;
};
export type apiUpdateThemeGroupMutationResponse = {
    readonly updateThemeGroup: {
        readonly themeGroup: {
            readonly id: string;
            readonly name: string;
            readonly description: string;
            readonly themes: Record<string, any>;
        };
    };
};
export type apiUpdateThemeGroupMutation = {
    readonly response: apiUpdateThemeGroupMutationResponse;
    readonly variables: apiUpdateThemeGroupMutationVariables;
};



/*
mutation apiUpdateThemeGroupMutation(
  $input: UpdateThemeGroupInput!
) {
  updateThemeGroup(input: $input) {
    themeGroup {
      id
      name
      description
      themes
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateThemeGroupPayload",
    "kind": "LinkedField",
    "name": "updateThemeGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ThemeGroup",
        "kind": "LinkedField",
        "name": "themeGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "themes",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "apiUpdateThemeGroupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "apiUpdateThemeGroupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5c9f8dd657d5a00fe21632deb7428b47",
    "id": null,
    "metadata": {},
    "name": "apiUpdateThemeGroupMutation",
    "operationKind": "mutation",
    "text": "mutation apiUpdateThemeGroupMutation(\n  $input: UpdateThemeGroupInput!\n) {\n  updateThemeGroup(input: $input) {\n    themeGroup {\n      id\n      name\n      description\n      themes\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cc0f04e966f4ce3e35ce80f4f7aad4a3';
export default node;
