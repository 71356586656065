/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UnitsQueryVariables = {};
export type UnitsQueryResponse = {
    readonly units: ReadonlyArray<{
        readonly id: string;
        readonly otp: number | null;
        readonly unitNumber: number;
        readonly comment: string;
        readonly locationPhotoUrl: string;
        readonly name: string;
        readonly languageCode: string;
        readonly mac: string;
        readonly placedAt: string;
        readonly screenType: string;
        readonly dmxDefaultGain: number;
        readonly softlightDefaultGain: number;
        readonly updatedAt: unknown;
        readonly updatedBy: string;
        readonly createdAt: unknown;
        readonly createdBy: string;
        readonly themes: Record<string, any>;
        readonly themeGroups: Record<string, any>;
        readonly socketId: string | null;
        readonly sshUrl: string | null;
        readonly lastLoggingDate: unknown | null;
        readonly unitType: string | null;
        readonly lastLoggingFirmware: string | null;
        readonly sshStatus: string | null;
        readonly networkMac: string;
    }>;
    readonly themes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly thumbnailUrl: string;
        readonly isMediaComplete: boolean | null;
        readonly themeType: string | null;
        readonly workTitle: string;
    }>;
};
export type UnitsQuery = {
    readonly response: UnitsQueryResponse;
    readonly variables: UnitsQueryVariables;
};



/*
query UnitsQuery {
  units {
    id
    otp
    unitNumber
    comment
    locationPhotoUrl
    name
    languageCode
    mac
    placedAt
    screenType
    dmxDefaultGain
    softlightDefaultGain
    updatedAt
    updatedBy
    createdAt
    createdBy
    themes
    themeGroups
    socketId
    sshUrl
    lastLoggingDate
    unitType
    lastLoggingFirmware
    sshStatus
    networkMac
  }
  themes {
    id
    name
    thumbnailUrl
    isMediaComplete
    themeType
    workTitle
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Unit",
    "kind": "LinkedField",
    "name": "units",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationPhotoUrl",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "languageCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mac",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "placedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "screenType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dmxDefaultGain",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "softlightDefaultGain",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedBy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdBy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themeGroups",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "socketId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sshUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastLoggingDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastLoggingFirmware",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sshStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "networkMac",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Theme",
    "kind": "LinkedField",
    "name": "themes",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMediaComplete",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themeType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnitsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8f303316f2d2c5a76541eb5712e4220f",
    "id": null,
    "metadata": {},
    "name": "UnitsQuery",
    "operationKind": "query",
    "text": "query UnitsQuery {\n  units {\n    id\n    otp\n    unitNumber\n    comment\n    locationPhotoUrl\n    name\n    languageCode\n    mac\n    placedAt\n    screenType\n    dmxDefaultGain\n    softlightDefaultGain\n    updatedAt\n    updatedBy\n    createdAt\n    createdBy\n    themes\n    themeGroups\n    socketId\n    sshUrl\n    lastLoggingDate\n    unitType\n    lastLoggingFirmware\n    sshStatus\n    networkMac\n  }\n  themes {\n    id\n    name\n    thumbnailUrl\n    isMediaComplete\n    themeType\n    workTitle\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e7ed0f994994b868c9e737bdbd3fa31d';
export default node;
