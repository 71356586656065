import { makeStyles } from '@material-ui/core'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  Bar,
} from 'recharts'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    paddingTop: '10px',
  },
}))

interface ThemeWatchOverADayProps {
  width?: number
  themesHoursWatchedOverADay: Record<string, number[]>
  title: string
  combine?: boolean
  hideLegend?: boolean
}

const ThemeWatchOverADay = ({
  width,
  themesHoursWatchedOverADay,
  title,
  combine = false,
  hideLegend = false,
}: ThemeWatchOverADayProps) => {
  const classes = useStyles()

  const data = [...Array(24).keys()].map((hour) => {
    const themeNames = Object.keys(themesHoursWatchedOverADay)
    const obj = {
      name: `${hour}:00`,
    } as Record<string, any>
    themeNames.forEach((themeName) => {
      if (combine) {
        obj.combined =
          (obj.combined ?? 0) + themesHoursWatchedOverADay[themeName][hour]
      } else {
        obj[themeName] = themesHoursWatchedOverADay[themeName][hour]
      }
    })
    return obj
  })

  return (
    <div className={classes.wrapper}>
      <p>{title}</p>
      {themesHoursWatchedOverADay ? (
        <LineChart width={width} height={300} data={data}>
          <CartesianGrid stroke="#555555" />
          <XAxis interval={1} dataKey="name" stroke="white" />
          <YAxis interval={1} stroke="white" />
          {/* <Tooltip /> */}
          {Object.keys(data[0])
            .filter((n) => n !== 'name')
            .sort((a, b) => a.localeCompare(b))
            .map((themeName, i) => (
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey={themeName}
                stroke={RANDOM_COLORS[i % RANDOM_COLORS.length]}
                name={themeName}
                dot={false}
              />
            ))}
          {!hideLegend && <Legend />}
        </LineChart>
      ) : (
        <p>No data</p>
      )}
    </div>
  )
}

export default ThemeWatchOverADay

const RANDOM_COLORS = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
]
