/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PageViewInput = {
    customerStatisticsId: string;
};
export type StatsPageViewMutationVariables = {
    input: PageViewInput;
};
export type StatsPageViewMutationResponse = {
    readonly pageView: {
        readonly status: string;
    };
};
export type StatsPageViewMutation = {
    readonly response: StatsPageViewMutationResponse;
    readonly variables: StatsPageViewMutationVariables;
};



/*
mutation StatsPageViewMutation(
  $input: PageViewInput!
) {
  pageView(input: $input) {
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PageViewPayload",
    "kind": "LinkedField",
    "name": "pageView",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatsPageViewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatsPageViewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "06d6dcbe185b994514fd87917097b4eb",
    "id": null,
    "metadata": {},
    "name": "StatsPageViewMutation",
    "operationKind": "mutation",
    "text": "mutation StatsPageViewMutation(\n  $input: PageViewInput!\n) {\n  pageView(input: $input) {\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = '13347956d70346a06ab7f68a3090434c';
export default node;
