import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyBVb68KjKC2uHj9UJ7s90UTKeFlpHGi2cs',
  authDomain: 'wavecareutils.firebaseapp.com',
  projectId: 'wavecareutils',
  storageBucket: 'wavecareutils.appspot.com',
  messagingSenderId: '241529180557',
  appId: '1:241529180557:web:0df9a1fbddf6d2100e6dc4',
  measurementId: 'G-PJBPJMCC15',
}

firebase.initializeApp(firebaseConfig)
export const auth = firebase.auth()
