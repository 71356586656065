import * as XLSX from 'xlsx'

type ExportAsXlsxProps = {
  data: {
    title: string
    header?: string[]
    rows?: {
      name: string
      values: number[]
    }[]
  }[]
}

export const exportAsXlsx = (p: ExportAsXlsxProps) => {
  const output = [] as any[]

  p.data.forEach((d) => {
    output.push([d.title])
    d.header && output.push([undefined, ...d.header])
    d.rows &&
      d.rows.forEach((r) => {
        output.push([r.name, ...r.values])
      })
    output.push([])
  })

  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(output)

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  const base64 = XLSX.write(wb, {
    type: 'base64',
  })

  return base64
}
