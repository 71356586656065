/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateUnitInput = {
    comment: string;
    createdBy?: string | null | undefined;
    dmxDefaultGain: number;
    languageCode: string;
    mac: string;
    name: string;
    networkMac?: string | null | undefined;
    placedAt: string;
    screenType: string;
    softlightDefaultGain: number;
    sshStatus?: string | null | undefined;
    themeGroups?: Record<string, any> | null | undefined;
    themes?: Record<string, any> | null | undefined;
    unitType?: string | null | undefined;
};
export type apiCreateUnitMutationVariables = {
    input: CreateUnitInput;
};
export type apiCreateUnitMutationResponse = {
    readonly createUnit: {
        readonly unit: {
            readonly id: string;
            readonly unitNumber: number;
            readonly comment: string;
            readonly languageCode: string;
            readonly name: string;
            readonly mac: string;
            readonly placedAt: string;
            readonly screenType: string;
            readonly dmxDefaultGain: number;
            readonly softlightDefaultGain: number;
            readonly updatedAt: unknown;
            readonly updatedBy: string;
            readonly createdAt: unknown;
            readonly createdBy: string;
            readonly themes: Record<string, any>;
            readonly themeGroups: Record<string, any>;
            readonly unitType: string | null;
        };
    };
};
export type apiCreateUnitMutation = {
    readonly response: apiCreateUnitMutationResponse;
    readonly variables: apiCreateUnitMutationVariables;
};



/*
mutation apiCreateUnitMutation(
  $input: CreateUnitInput!
) {
  createUnit(input: $input) {
    unit {
      id
      unitNumber
      comment
      languageCode
      name
      mac
      placedAt
      screenType
      dmxDefaultGain
      softlightDefaultGain
      updatedAt
      updatedBy
      createdAt
      createdBy
      themes
      themeGroups
      unitType
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUnitPayload",
    "kind": "LinkedField",
    "name": "createUnit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Unit",
        "kind": "LinkedField",
        "name": "unit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "languageCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mac",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "placedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "screenType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dmxDefaultGain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "softlightDefaultGain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedBy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdBy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "themes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "themeGroups",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "apiCreateUnitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "apiCreateUnitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9e98dd93796e1b0b236dfedbf6714011",
    "id": null,
    "metadata": {},
    "name": "apiCreateUnitMutation",
    "operationKind": "mutation",
    "text": "mutation apiCreateUnitMutation(\n  $input: CreateUnitInput!\n) {\n  createUnit(input: $input) {\n    unit {\n      id\n      unitNumber\n      comment\n      languageCode\n      name\n      mac\n      placedAt\n      screenType\n      dmxDefaultGain\n      softlightDefaultGain\n      updatedAt\n      updatedBy\n      createdAt\n      createdBy\n      themes\n      themeGroups\n      unitType\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7af18204121ec305c5f9ffdf0111a3c9';
export default node;
