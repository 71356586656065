import { makeStyles } from '@material-ui/core/styles'
import { DMX } from '@types'

interface ColorThumbnailProps {
  dmx: DMX
  dmxDefaultGain: number
  small?: boolean
}

const ColorThumbnail = ({
  dmx,
  dmxDefaultGain,
  small,
}: ColorThumbnailProps) => {
  const classes = useStyles()

  const r = dmx.r || 0
  const g = dmx.g || 0
  const b = dmx.b || 0
  const w = dmx.w || 0

  return (
    <>
      <div className={classes.root}>
        <div className={classes.colorWrapper}>
          <div
            className={classes.smallColor}
            style={{
              opacity: dmxDefaultGain / 100,
              backgroundColor: `rgb(${Math.max(r, w)},${Math.max(
                g,
                w,
              )},${Math.max(b, w)})`,
            }}
          />
          <div
            className={classes.smallColor}
            style={{
              backgroundColor: `rgb(${Math.max(r, w)},${Math.max(
                g,
                w,
              )},${Math.max(b, w)})`,
            }}
          />
        </div>
        {!small &&
          ['r', 'g', 'b', 'w'].map((c) => {
            return (
              <div className={classes.channelValue}>
                <p className={classes.channelName}>{c.toUpperCase()}:</p>
                <p>{dmx[c as keyof DMX] || 0}</p>
              </div>
            )
          })}
        <div className={classes.channelValue}>
          <p className={classes.channelName}>%</p>
          <p>{dmxDefaultGain}</p>
        </div>
      </div>
    </>
  )
}

export default ColorThumbnail

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  smallColor: {
    minWidth: 10,
    minHeight: 40,
    backgroundColor: '#ffffff10',
  },
  colorWrapper: {
    marginRight: 10,
    backgroundColor: '#000',
    display: 'flex',
    borderRadius: 4,
    overflow: 'hidden',
  },
  channelValue: {
    width: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  channelName: {
    fontSize: 10,
    opacity: 0.5,
  },
}))
