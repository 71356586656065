import { StorageItem } from '../Storage'
import { makeStyles } from '@material-ui/core'
import ImageIcon from '@mui/icons-material/Image'
import VideoIcon from '@mui/icons-material/OndemandVideo'
import SoundIcon from '@mui/icons-material/LibraryMusic'
import SoftlightIcon from '@mui/icons-material/BlurOn'
import SoftwareIcon from '@mui/icons-material/Wysiwyg'
import EncryptedIcon from '@mui/icons-material/Lock'
import UnknownIcon from '@mui/icons-material/HelpCenter'
import { fileTypeFromFilename } from 'helpers/fileHelper'
import Tooltip from '@mui/material/Tooltip'
import filesize from 'filesize'
import { formatDateString } from 'helpers/dateHelper'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 160,
    maxWidth: 160,
    padding: 8,
    cursor: 'pointer',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#ffffff10',
    },
  },
  title: {
    height: 80,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textAlign: 'center',
    justifyContent: 'flex-end',
    wordBreak: 'break-all',
  },
  type: {
    marginTop: 10,
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  description: {
    color: '#ffffff70',
  },
  thumbnail: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: 20,
  },
}))

interface ItemCardProps {
  item: StorageItem | null | undefined
  onClick: () => void
}

const ItemCard = ({ item, onClick }: ItemCardProps) => {
  const classes = useStyles()

  if (!item) return null

  const FileIcon = () => {
    const Icon = {
      'image': ImageIcon,
      'video': VideoIcon,
      'sound': SoundIcon,
      'softlight': SoftlightIcon,
      'software': SoftwareIcon,
      'encrypted': EncryptedIcon,
      'unknown': UnknownIcon,
    }[fileTypeFromFilename(item.Key)]

    return (
      <Icon style={{ fontSize: 50 }} />
    )
  }

  if (!item.Size) return null

  return (
    <Tooltip
      title={`Name: ${item.Key} | Date: ${formatDateString(item.LastModified)}`}
    >
      <div className={classes.container} onClick={onClick}>
        <div
          className={classes.thumbnail}
          style={{ backgroundImage: `url(${item.thumbnailSignedUrl})` }}
        >
          <FileIcon />
        </div>
        {item.Key.includes('/') ? (
          <>
            <div className={classes.type}>{item.Key.split('/')[0]}</div>
            <div className={classes.title}>{item.Key.split('/')[1]}</div>
          </>
        ) : (
          <div className={classes.title}>{item.Key}</div>
        )}
        <div className={classes.description}>
          {item.Size ? filesize(item.Size) : ''}
        </div>
      </div>
    </Tooltip>
  )
}

export default ItemCard
