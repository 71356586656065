import { CircularProgress, makeStyles } from '@material-ui/core'
import MaterialTable from 'material-table'
import { useQuery } from 'react-query'
import { useAuth } from 'hooks'

const useStyles = makeStyles(() => ({
  page: {
    position: 'relative',
  },
  table: {
    width: '100%',
    position: 'relative',
    height: 400,
  },
}))

const Releases = () => {
  const { page } = useStyles()
  const { user } = useAuth()

  const { isLoading, data } = useQuery<string[]>(
    'releases',
    async () =>
      fetch(process.env.REACT_APP_API_ENDPOINT + '/storage/releases', {
        headers: new Headers({
          Authorization: `Bearer ${await user?.getIdToken()}`,
        }),
      }).then((res) => res.json()),
    {
      refetchOnMount: true,
    },
  )

  if (isLoading) return <CircularProgress />

  return (
    <div className={page}>
      {data && (
        <MaterialTable
          title=""
          columns={[{ field: 'key', title: 'Key' }]}
          options={{
            pageSize: 10,
            sorting: true,
            exportButton: true,
            headerStyle: { backgroundColor: 'transparent' },
          }}
          style={{ backgroundColor: 'transparent' }}
          data={data.map((key) => ({ key })).reverse()}
        />
      )}
    </div>
  )
}

export default Releases
