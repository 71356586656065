/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteThemeInput = {
    id: string;
};
export type apiDeleteThemeMutationVariables = {
    input: DeleteThemeInput;
};
export type apiDeleteThemeMutationResponse = {
    readonly deleteTheme: {
        readonly status: string;
        readonly unitCount: number | null;
        readonly themeGroupCount: number | null;
    };
};
export type apiDeleteThemeMutation = {
    readonly response: apiDeleteThemeMutationResponse;
    readonly variables: apiDeleteThemeMutationVariables;
};



/*
mutation apiDeleteThemeMutation(
  $input: DeleteThemeInput!
) {
  deleteTheme(input: $input) {
    status
    unitCount
    themeGroupCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteThemePayload",
    "kind": "LinkedField",
    "name": "deleteTheme",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themeGroupCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "apiDeleteThemeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "apiDeleteThemeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "98ef9479d51633032696f49a4a5751a7",
    "id": null,
    "metadata": {},
    "name": "apiDeleteThemeMutation",
    "operationKind": "mutation",
    "text": "mutation apiDeleteThemeMutation(\n  $input: DeleteThemeInput!\n) {\n  deleteTheme(input: $input) {\n    status\n    unitCount\n    themeGroupCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '85c690120da04594af9cc988b707033b';
export default node;
