import { makeStyles } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'

const useStyles = makeStyles({
  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '& td:first-child, th:first-child': {
          backgroundColor: '#444',
          position: 'sticky',
          left: 0,
          zIndex: 1,
        },
        '& th:first-child': {
          zIndex: 11,
        },
      },
    },
  },
})

export const ThemeOverAPeriodTable = ({
  data,
  strings,
  language,
  startDate,
  endDate,
}: {
  data: Record<string, Record<string, number>>
  strings: {
    name: string
    watched: string
  }
  language: string
  startDate: Date | null
  endDate: Date | null
}) => {
  const classes = useStyles()

  if (!moment(endDate).isValid || !moment(endDate).isValid)
    return <p>Choose a start and end date</p>

  const days = moment(endDate).diff(moment(startDate), 'days')

  if (days < 1 || isNaN(days)) return <p>Not a valid date</p>

  const keys = new Set(
    Object.keys(data)
      .map((k) => data[k])
      .map((v) => Object.keys(v))
      .flat(),
  )

  const formatter = new Intl.DateTimeFormat(language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  const colums = [...keys].map((themeName) => {
    return {
      name: themeName,
      data: [...Array(days).keys()].map((day) => {
        const date = moment(startDate).add(day, 'days').toDate()

        const dayKey = Math.floor(date.getTime() / 1000 / 60 / 60 / 24)

        return {
          dateName: formatter.format(date),
          watched: data[dayKey]?.[themeName] || 0,
        }
      }),
    }
  })

  const colorscale = [
    '#ffffff08',
    'rgba(253, 239, 179)',
    'rgb(253, 237, 176)',
    'rgb(249, 198, 139)',
    'rgb(244, 159, 109)',
    'rgb(234, 120, 88)',
    'rgb(218, 83, 82)',
    'rgb(191, 54, 91)',
    'rgb(158, 35, 98)',
    'rgb(120, 26, 97)',
    'rgb(83, 22, 84)',
    'rgb(47, 15, 61)',
  ]

  const maxValue = Math.max(
    ...colums.map((d) => Math.max(...d.data.flatMap((d) => d.watched))),
  )

  if (colums.length === 0) return <p>Not enough data</p>

  return (
    <div className={classes.stickyActionsColumn}>
      <MaterialTable
        title=""
        columns={[
          {
            field: 'name',
            title: (
              <div style={{ display: 'fixed', width: 200 }}>{strings.name}</div>
            ),
            width: '50%',
          },
          {
            field: 'watched',
            width: '50%',
            title: (
              <div
                style={{
                  height: 26,
                  width: '100%',
                  display: 'flex',
                  transform: `translateY(20px)`,
                  marginTop: 6,
                  marginBottom: 12,
                  alignItems: 'flex-end',
                }}
              >
                {colums[0].data.map(({ dateName }, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        minWidth: 20,
                        maxWidth: 20,
                        minHeight: 20,
                        maxHeight: 20,
                        paddingRight: 4,
                        marginBottom: 4,
                      }}
                    >
                      {i % 7 === 0 && (
                        <div
                          style={{
                            color: 'white',
                            fontSize: 10,
                            width: 120,
                            marginLeft: 4,
                          }}
                        >
                          {dateName}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ),
            render: (rowData) => (
              <div
                style={{
                  width: '100%',
                  height: 25,
                  borderRadius: '6px',
                  display: 'flex',
                }}
              >
                {rowData.data.map(({ watched }, i) => {
                  const gradientIndex = Math.min(
                    Math.ceil(
                      (Math.round(watched * 10) / (maxValue * 10)) *
                        (colorscale.length - 1),
                    ),
                    colorscale.length - 1,
                  )

                  return (
                    <div
                      key={i}
                      style={{
                        minWidth: 20,
                        maxWidth: 20,
                        minHeight: 20,
                        maxHeight: 20,
                        paddingRight: 2,
                        paddingLeft: 2,
                        position: 'relative',
                      }}
                    >
                      <div
                        key={i}
                        style={{
                          borderRadius: '4px',
                          minWidth: 18,
                          maxWidth: 18,
                          minHeight: 18,
                          maxHeight: 18,
                          backgroundColor: colorscale[gradientIndex],
                          marginBottom: 4,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {gradientIndex !== 0 && (
                          <div
                            style={{
                              color: gradientIndex > 5 ? 'white' : '#00000099',
                              fontWeight: 'bold',
                              fontSize: 10,
                            }}
                          >
                            {Math.round(watched)}
                          </div>
                        )}
                      </div>
                      {i % 7 === 0 && (
                        <div
                          style={{
                            borderLeft: '1px solid #ffffff',
                            transform: 'scaleY(2)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            ),
          },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 50, 100, 150, 200],
          sorting: false,
          exportButton: true,
          headerStyle: {
            backgroundColor: '#444',
          },
          rowStyle: {
            backgroundColor: '#444',
          },
          padding: 'dense',
        }}
        style={{ backgroundColor: '#444' }}
        data={colums}
      />
    </div>
  )
}
