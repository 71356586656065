/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ThemeGroupsThemesQueryVariables = {};
export type ThemeGroupsThemesQueryResponse = {
    readonly themes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly titleObject: Record<string, any>;
        readonly themeType: string | null;
        readonly descriptionObject: Record<string, any>;
        readonly thumbnailUrl: string;
        readonly updatedAt: unknown;
        readonly createdAt: unknown;
        readonly isMediaComplete: boolean | null;
        readonly workTitle: string;
    }>;
};
export type ThemeGroupsThemesQuery = {
    readonly response: ThemeGroupsThemesQueryResponse;
    readonly variables: ThemeGroupsThemesQueryVariables;
};



/*
query ThemeGroupsThemesQuery {
  themes {
    id
    name
    titleObject
    themeType
    descriptionObject
    thumbnailUrl
    updatedAt
    createdAt
    isMediaComplete
    workTitle
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Theme",
    "kind": "LinkedField",
    "name": "themes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "titleObject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themeType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "descriptionObject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMediaComplete",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ThemeGroupsThemesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ThemeGroupsThemesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "972664b1c62a196444ad66ff1f7deb3e",
    "id": null,
    "metadata": {},
    "name": "ThemeGroupsThemesQuery",
    "operationKind": "query",
    "text": "query ThemeGroupsThemesQuery {\n  themes {\n    id\n    name\n    titleObject\n    themeType\n    descriptionObject\n    thumbnailUrl\n    updatedAt\n    createdAt\n    isMediaComplete\n    workTitle\n  }\n}\n"
  }
};
})();
(node as any).hash = '0d98bcea68e175a6e8af6d2476ed104f';
export default node;
