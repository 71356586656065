import React from 'react'
import './App.css'

import { AuthProvider } from 'context'
import Routes from './Routes'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 86_400_000,
    },
  },
})

const App = () => {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: 'dark',
          primary: {
            main: '#8F4F4D',
          },
          secondary: {
            main: '#E09E67',
          },
        },
      }),
    [],
  )

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
