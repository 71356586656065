/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MobileAppQueryVariables = {
    input: string;
};
export type MobileAppQueryResponse = {
    readonly mobileAppKanban: {
        readonly id: string;
        readonly columns: Record<string, any>;
    };
    readonly themesByType: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly categories: ReadonlyArray<string>;
        readonly signedThumbnailUrl: string | null;
    }>;
};
export type MobileAppQuery = {
    readonly response: MobileAppQueryResponse;
    readonly variables: MobileAppQueryVariables;
};



/*
query MobileAppQuery(
  $input: String!
) {
  mobileAppKanban {
    id
    columns
  }
  themesByType(input: $input) {
    id
    name
    categories
    signedThumbnailUrl
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MobileAppKanban",
    "kind": "LinkedField",
    "name": "mobileAppKanban",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "columns",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Theme",
    "kind": "LinkedField",
    "name": "themesByType",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "categories",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedThumbnailUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileAppQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MobileAppQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5d322f519c10d1b0b59d52a8e71174a1",
    "id": null,
    "metadata": {},
    "name": "MobileAppQuery",
    "operationKind": "query",
    "text": "query MobileAppQuery(\n  $input: String!\n) {\n  mobileAppKanban {\n    id\n    columns\n  }\n  themesByType(input: $input) {\n    id\n    name\n    categories\n    signedThumbnailUrl\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e4ee371110271a108a1f0a9afc0d104f';
export default node;
