import graphql from 'babel-plugin-relay/macro'
import { useMutation } from 'hooks/useMutation'
import { LanguageCode, ScreenType } from './Units'
import { apiCreateUnitMutation } from './__generated__/apiCreateUnitMutation.graphql'
import { apiDeleteUnitMutation } from './__generated__/apiDeleteUnitMutation.graphql'
import { apiGenerateOtpMutation } from './__generated__/apiGenerateOtpMutation.graphql'
import { apiUpdateUnitMutation } from './__generated__/apiUpdateUnitMutation.graphql'

export type Unit = {
  id: string
  unitNumber?: number
  languageCode: LanguageCode
  name: string
  comment?: string | null
  mac: string
  networkMac?: string | null
  placedAt: string
  screenType: ScreenType
  dmxDefaultGain: number
  softlightDefaultGain: number
  otp?: number | null
  createdAt?: Date | string | null
  createdBy?: string | null
  updatedAt?: Date | string | null
  updatedBy?: string | null
  deletedAt?: Date | null
  themes?: string[] | null
  themeGroups?: string[] | null
  socketId?: string | null
  sshUrl?: string | null
  unitType?: string | null
  sshStatus?: string | null
  lastLoggingDate?: string | null
}

export const useApi = () => {
  const createUnit = useMutation<apiCreateUnitMutation>(
    graphql`
      mutation apiCreateUnitMutation($input: CreateUnitInput!) {
        createUnit(input: $input) {
          unit {
            id
            unitNumber
            comment
            languageCode
            name
            mac
            placedAt
            screenType
            dmxDefaultGain
            softlightDefaultGain
            updatedAt
            updatedBy
            createdAt
            createdBy
            themes
            themeGroups
            unitType
          }
        }
      }
    `,
    {
      updater: (store) => {
        const root = store.getRoot()
        const unit = store.getRootField('createUnit')?.getLinkedRecord('unit')
        const units = root.getLinkedRecords('units') as Array<typeof unit>
        const updatedUnits = [unit].concat(units)
        root.setLinkedRecords(updatedUnits, 'units')
      },
    },
  )

  const updateUnit = useMutation<apiUpdateUnitMutation>(graphql`
    mutation apiUpdateUnitMutation($input: UpdateUnitInput!) {
      updateUnit(input: $input) {
        unit {
          id
          unitNumber
          languageCode
          name
          comment
          mac
          placedAt
          screenType
          dmxDefaultGain
          softlightDefaultGain
          updatedAt
          updatedBy
          createdAt
          createdBy
          themes
          themeGroups
          unitType
          sshStatus
        }
      }
    }
  `)

  const deleteUnit = useMutation<apiDeleteUnitMutation>(
    graphql`
      mutation apiDeleteUnitMutation($input: DeleteUnitInput!) {
        deleteUnit(input: $input) {
          status
        }
      }
    `,
    (input) => ({
      updater: (store) => {
        const listOfRecords = store.getRoot().getLinkedRecords('units')
        const newList = listOfRecords?.filter(
          (record) => record.getDataID() !== input.id,
        )
        store.getRoot().setLinkedRecords(newList, 'units')
      },
    }),
  )

  const generateOtp = useMutation<apiGenerateOtpMutation>(graphql`
    mutation apiGenerateOtpMutation($input: GenerateOtpInput!) {
      generateOtp(input: $input) {
        unit {
          id
          otp
        }
      }
    }
  `)

  return {
    createUnit,
    updateUnit,
    deleteUnit,
    generateOtp,
  }
}
