import { FC, useRef } from 'react'
import { Button, makeStyles, Tooltip } from '@material-ui/core'
import { ImageObject } from './ItemCardAlternative'
import filesize from 'filesize'
import { formatDateString } from 'helpers/dateHelper'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: 8,
    border: '1px solid #666666',
    margin: '8px',
    borderRadius: 8,
  },
  content: {
    padding: 10,
    width: '100%',
  },
  thumbnail: {
    display: 'flex',
    minWidth: 140,
    minHeight: 140,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: 20,
  },
}))

type Props = {
  item: ImageObject
  onDeleteImageClicked: () => void
}

const ThumbnailCard: FC<Props> = (p: Props) => {
  const thumbnailUrl = useRef(p.item.signedUrl).current

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div
        className={classes.thumbnail}
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
      />
      <div className={classes.content}>
        <p>
          {p.item.filename} ({filesize(p.item.file.Size)})
        </p>
        <p>{formatDateString(p.item.file.LastModified)}</p>
      </div>
      <Button onClick={p.onDeleteImageClicked} color="secondary">
        Delete image
      </Button>
    </div>
  )
}

export default ThumbnailCard
