/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteThemeInput = {
    id: string;
};
export type apiCanDeleteThemeMutationVariables = {
    input: DeleteThemeInput;
};
export type apiCanDeleteThemeMutationResponse = {
    readonly canDeleteTheme: {
        readonly status: string;
        readonly unitCount: number | null;
        readonly themeGroupCount: number | null;
    };
};
export type apiCanDeleteThemeMutation = {
    readonly response: apiCanDeleteThemeMutationResponse;
    readonly variables: apiCanDeleteThemeMutationVariables;
};



/*
mutation apiCanDeleteThemeMutation(
  $input: DeleteThemeInput!
) {
  canDeleteTheme(input: $input) {
    status
    unitCount
    themeGroupCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteThemePayload",
    "kind": "LinkedField",
    "name": "canDeleteTheme",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themeGroupCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "apiCanDeleteThemeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "apiCanDeleteThemeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "85b0c4c647ca1dad15572fa6c67e27f4",
    "id": null,
    "metadata": {},
    "name": "apiCanDeleteThemeMutation",
    "operationKind": "mutation",
    "text": "mutation apiCanDeleteThemeMutation(\n  $input: DeleteThemeInput!\n) {\n  canDeleteTheme(input: $input) {\n    status\n    unitCount\n    themeGroupCount\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f88d97002fe5314b2e044e2faff62d9f';
export default node;
