/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateThemeInput = {
    categories?: Array<string> | null | undefined;
    defaultSoftlightGain?: number | null | undefined;
    descriptionObject?: Record<string, any> | null | undefined;
    dmx?: Record<string, any> | null | undefined;
    dmxDefaultGain: number;
    durationInSeconds?: number | null | undefined;
    id: string;
    name?: string | null | undefined;
    rawVideoUrl?: string | null | undefined;
    softlightUrl?: string | null | undefined;
    soundUrl?: string | null | undefined;
    themeType?: string | null | undefined;
    thumbnailUrl?: string | null | undefined;
    titleObject?: Record<string, any> | null | undefined;
    videoUrl?: string | null | undefined;
    workTitle?: string | null | undefined;
};
export type apiUpdateThemeMutationVariables = {
    input: UpdateThemeInput;
};
export type apiUpdateThemeMutationResponse = {
    readonly updateTheme: {
        readonly theme: {
            readonly id: string;
            readonly name: string;
            readonly titleObject: Record<string, any>;
            readonly descriptionObject: Record<string, any>;
            readonly rawVideoUrl: string;
            readonly thumbnailUrl: string;
            readonly signedThumbnailUrl: string | null;
            readonly videoUrl: string;
            readonly softlightUrl: string;
            readonly soundUrl: string;
            readonly dmx: Record<string, any>;
            readonly createdAt: unknown;
            readonly updatedAt: unknown;
            readonly themeType: string | null;
            readonly categories: ReadonlyArray<string>;
            readonly isMediaComplete: boolean | null;
        };
    };
};
export type apiUpdateThemeMutation = {
    readonly response: apiUpdateThemeMutationResponse;
    readonly variables: apiUpdateThemeMutationVariables;
};



/*
mutation apiUpdateThemeMutation(
  $input: UpdateThemeInput!
) {
  updateTheme(input: $input) {
    theme {
      id
      name
      titleObject
      descriptionObject
      rawVideoUrl
      thumbnailUrl
      signedThumbnailUrl
      videoUrl
      softlightUrl
      soundUrl
      dmx
      createdAt
      updatedAt
      themeType
      categories
      isMediaComplete
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateThemePayload",
    "kind": "LinkedField",
    "name": "updateTheme",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Theme",
        "kind": "LinkedField",
        "name": "theme",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "titleObject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "descriptionObject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawVideoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnailUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signedThumbnailUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "videoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "softlightUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "soundUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dmx",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "themeType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "categories",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMediaComplete",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "apiUpdateThemeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "apiUpdateThemeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c776183965b50a09601ba12c291b666f",
    "id": null,
    "metadata": {},
    "name": "apiUpdateThemeMutation",
    "operationKind": "mutation",
    "text": "mutation apiUpdateThemeMutation(\n  $input: UpdateThemeInput!\n) {\n  updateTheme(input: $input) {\n    theme {\n      id\n      name\n      titleObject\n      descriptionObject\n      rawVideoUrl\n      thumbnailUrl\n      signedThumbnailUrl\n      videoUrl\n      softlightUrl\n      soundUrl\n      dmx\n      createdAt\n      updatedAt\n      themeType\n      categories\n      isMediaComplete\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'dba4c88c6471c52aa77f6155c37c0359';
export default node;
