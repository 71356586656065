/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CustomColorsQueryVariables = {};
export type CustomColorsQueryResponse = {
    readonly defaultCustomColorRef: {
        readonly id: string;
        readonly customColor: ReadonlyArray<Record<string, any>>;
    };
};
export type CustomColorsQuery = {
    readonly response: CustomColorsQueryResponse;
    readonly variables: CustomColorsQueryVariables;
};



/*
query CustomColorsQuery {
  defaultCustomColorRef {
    id
    customColor
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomColorRef",
    "kind": "LinkedField",
    "name": "defaultCustomColorRef",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customColor",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomColorsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomColorsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "711e3d1dbc09b3ea5170cc700aa61960",
    "id": null,
    "metadata": {},
    "name": "CustomColorsQuery",
    "operationKind": "query",
    "text": "query CustomColorsQuery {\n  defaultCustomColorRef {\n    id\n    customColor\n  }\n}\n"
  }
};
})();
(node as any).hash = '3c886ce3a07f301c06632e4d2817b823';
export default node;
