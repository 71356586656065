import { FC, useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { StorageItem } from '../Storage'
import ProcessedFileLineV2 from './ProcessedFileLineV2'
import axios from 'axios'
import { useAuth } from 'hooks'
import { formatDateString } from 'helpers/dateHelper'

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #666666',
    backgroundColor: '#444',
    borderRadius: 8,
    display: 'flex',
  },
  content: {
    padding: 10,
    width: '100%',
  },
  bold: {
    minWidth: 140,
  },
  title: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    justifyContent: 'flex-end',
    wordBreak: 'break-all',
  },
  lastModified: {
    fontSize: 12,
    opacity: 0.5,
  },
  type: {
    marginTop: 10,
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  description: {
    color: '#ffffff70',
  },
  thumbnail: {
    display: 'flex',
    minHeight: '100%',
    minWidth: 120,
    maxWidth: 120,
    borderRadius: 4,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'flex-end',
  },
  formats: {
    display: 'flex',
  },
  fullWidth: {
    width: '100%',
  },
}))

type Props = {
  signedImageUrl: string
  filename: string
  onSelectNew: () => void
}

const FileCardV2Simple: FC<Props> = (p: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div
        className={classes.thumbnail}
        style={{ backgroundImage: `url(${p.signedImageUrl})` }}
      />
      <div className={classes.fullWidth}>
        <div className={classes.content}>
          <div className={classes.title}>
            {p.filename ? p.filename : 'No theme file selected'}
          </div>
        </div>
        {p.onSelectNew && (
          <Button onClick={p.onSelectNew} color="secondary">
            Update file
          </Button>
        )}
      </div>
    </div>
  )
}

export default FileCardV2Simple
