import { useCallback, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import { uploadFile } from 'api/uploadFile'
import { useAuth } from 'hooks'

const useStyles = makeStyles((theme) => ({
  dragAndDropArea: {
    height: 100,
    backgroundColor: '#333',
    borderRadius: 8,
    border: '1px solid #666',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginLeft: 16,
  },
  uploadBar: {
    height: 10,
    marginTop: 10,
    width: '90%',
    backgroundColor: '#333',
    borderRadius: 8,
    border: '1px solid #666',
    display: 'flex',
  },
  primaryBackground: {
    backgroundColor: theme.palette.primary.main,
  },
  secondaryBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

interface UploadFieldProps {
  getSignedUrl: (filename: string, jwt: string) => Promise<string | null>
  title: string
  uploadedFile?: () => void
  overwriteFilename?: string
}

type FileToUpload = {
  filename: string
  file: File
  signedUrl: string
}

const UploadField = ({
  getSignedUrl,
  title,
  uploadedFile,
  overwriteFilename,
}: UploadFieldProps) => {
  const classes = useStyles()
  const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([])
  const { getAuthToken } = useAuth()

  const onDrop = useCallback(async (files: File[]) => {
    const jwt = await getAuthToken()
    const _filesToUpload = await Promise.all(
      files.map(async (file) => {
        if (overwriteFilename)
          return {
            filename: overwriteFilename,
            file,
            signedUrl: await getSignedUrl(overwriteFilename, jwt),
          } as FileToUpload
        const ts = Math.floor(new Date().getTime() / 1000)
        const specialFileName = `${ts}_${file.name.replace(/\s/g, '_')}`
          .toLocaleLowerCase()
          .replace(/[æ]/g, 'ae')
          .replace(/[ø]/g, 'oe')
          .replace(/[å]/g, 'aa')
        const presignedUrl = await getSignedUrl(specialFileName, jwt)
        return {
          filename: specialFileName,
          file,
          signedUrl: presignedUrl,
        } as FileToUpload
      }),
    )
    setFilesToUpload(_filesToUpload)
  }, [])

  return (
    <>
      {!Object.keys(filesToUpload).length ? (
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div className={classes.dragAndDropArea} {...getRootProps()}>
                <input {...getInputProps()} />
                <h2 className={classes.title}>{title}</h2>
              </div>
            </section>
          )}
        </Dropzone>
      ) : (
        <>
          {filesToUpload.map((fileToUpload) => (
            <UploadProgressItem
              fileToUpload={fileToUpload}
              uploadedFile={uploadedFile}
            />
          ))}
        </>
      )}
    </>
  )
}

export default UploadField

type UploadProgressItemProps = {
  fileToUpload: FileToUpload
  uploadedFile?: () => void
}

const UploadProgressItem = ({
  fileToUpload,
  uploadedFile,
}: UploadProgressItemProps) => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const classes = useStyles()

  useEffect(() => {
    const upload = async () => {
      const { file, signedUrl } = fileToUpload
      uploadFile(signedUrl, file, (_, percent) => {
        if (percent === 100) uploadedFile && uploadedFile()
        setUploadProgress(percent)
      })
    }
    upload()
  }, [fileToUpload])

  return (
    <div className={classes.dragAndDropArea}>
      {uploadProgress !== 100 ? (
        <div>
          Uploading {fileToUpload.filename} - {uploadProgress}%
        </div>
      ) : (
        <div>Success: {fileToUpload.filename} uploaded!</div>
      )}
      <div className={classes.uploadBar}>
        <div
          className={
            uploadProgress !== 100
              ? classes.primaryBackground
              : classes.secondaryBackground
          }
          style={{ width: `${uploadProgress || 0}%` }}
        />
      </div>
    </div>
  )
}
