import graphql from 'babel-plugin-relay/macro'
import { useMutation } from 'hooks/useMutation'
import { apiUpdateCustomColorRefMutation } from './__generated__/apiUpdateCustomColorRefMutation.graphql'

export const useApi = () => {
  const updateCustomColorRef =
    useMutation<apiUpdateCustomColorRefMutation>(graphql`
      mutation apiUpdateCustomColorRefMutation(
        $input: UpdateCustomColorRefInput!
      ) {
        updateCustomColorRef(input: $input) {
          customColorRef {
            id
            customColor
          }
        }
      }
    `)

  return {
    updateCustomColorRef,
  }
}
