import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'

interface ItemFilterProps {
  label: string
  date: Date | null
  setDate: (date: Date | null) => void
}

const ItemFilter = ({
  label,
  date,
  setDate
}: ItemFilterProps) => {
  return (
    <DatePicker
      label={label}
      value={date}
      onChange={setDate}
      renderInput={(params) => <TextField
        {...params}
        sx={{
          svg: { color: 'white' },
          input: { color: 'white' },
          label: { color: 'white' }
        }}
      />}
    />
  )
}

export default ItemFilter
