export const mapTypeToFilename = (
  filename?: string,
  type?: string,
): string | undefined => {
  return (
    (type &&
      {
        original: filename,
        thumbnail: filename + '_thumbnail.png',
        sound: filename + '.wave',
        softlight: filename + '.wavecare',
        encrypted: filename + '.encrypted.mp4',
      }[type]) ||
    undefined
  )
}

export const mapFilenameToStoragePath = (
  filename?: string,
): string | undefined => {
  if (!filename) return undefined
  const filenameLowerCase = filename.toLocaleLowerCase()
  let prefix

  if (filenameLowerCase.endsWith('.encrypted.mp4')) {
    prefix = 'encrypted/'
  } else if (filenameLowerCase.endsWith('.wave')) {
    prefix = 'sound/'
  } else if (filenameLowerCase.endsWith('.wavecare')) {
    prefix = 'softlight/'
  } else if (
    filenameLowerCase.endsWith('.mp4') ||
    filenameLowerCase.endsWith('.mov')
  ) {
    prefix = 'original/'
  } else {
    prefix = 'thumbnail/'
  }

  return prefix + filename
}
