import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

import { Chip as ChipProp } from '@types'

const useStyles = makeStyles(() => ({
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 12,
    paddingTop: 16,
    paddingBottom: 8,
  },
}))

interface ChipSelectorProps {
  chips: ChipProp[] | null
  setChips: React.Dispatch<React.SetStateAction<ChipProp[]>>
  onlyOne?: boolean
  disableAll?: boolean
}

const ChipSelector = ({
  chips,
  setChips,
  onlyOne = false,
  disableAll = false,
}: ChipSelectorProps) => {
  const classes = useStyles()

  const handleClick = (chip: ChipProp) => {
    if (!chips) return
    let temp = [...chips]
    temp = onlyOne ? temp.map((x) => ({ ...x, isSelected: false })) : temp
    temp[temp.findIndex((x) => x.id === chip.id)] = {
      ...chip,
      isSelected: !chip.isSelected,
    }
    setChips(temp)
  }

  const removeAll = () => {
    if (!chips) return
    const temp = [...chips]
    setChips(temp.map((x) => ({ ...x, isSelected: false })))
  }

  return (
    <div>
      <div className={classes.chipContainer}>
        {chips &&
          chips.map((chip) => {
            return (
              <Chip
                key={chip.id}
                onClick={() =>
                  (!chip.disabled || !disableAll) && handleClick(chip)
                }
                label={chip.name}
                variant={chip.isSelected ? 'default' : 'outlined'}
                style={{
                  marginRight: chip.isSelected ? 4 : 3,
                  marginLeft: chip.isSelected ? 4 : 3,
                  marginBottom: 8,
                  opacity: chip.disabled || disableAll ? 0.2 : 1,
                  borderColor: '#E09E67',
                  color: chip.isSelected ? '#222' : '#E09E67',
                  backgroundColor: chip.isSelected ? '#E09E67' : 'transparent',
                }}
              />
            )
          })}
        {!onlyOne && (
          <Chip
            onClick={removeAll}
            label="Remove filter"
            variant="outlined"
            style={{
              marginRight: 3,
              marginLeft: 3,
              marginBottom: 8,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default ChipSelector
