import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import { FC, Suspense, useEffect, useMemo } from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay'
import { MobileStatsQuery } from './__generated__/MobileStatsQuery.graphql'
import BarChart from '../Home/components/BarChart'

const useStyles = makeStyles((theme: any) => ({
  page: {
    padding: theme.spacing(2),
  },
  item: {
    backgroundColor: '#444',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}))

const mobileStatsQuery = graphql`
  query MobileStatsQuery {
    mobileAppUsers {
      totalAmount
    }
    accumulatedMobileAppStats {
      stats
    }
    accumulatedMobileAppQRLocations {
      statsAccumulated
    }
  }
`

const MobileStatsContainer = () => {
  const [mobileStatsQueryReference, loadStatsQuery] =
    useQueryLoader<MobileStatsQuery>(mobileStatsQuery)

  useEffect(() => {
    loadStatsQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<CircularProgress style={{ margin: 16 }} />}>
      {mobileStatsQueryReference && (
        <MobileStats mobileStatsQueryReference={mobileStatsQueryReference} />
      )}
    </Suspense>
  )
}

interface Props {
  mobileStatsQueryReference: PreloadedQuery<MobileStatsQuery>
}

const MobileStats: FC<Props> = (p) => {
  const {
    mobileAppUsers,
    accumulatedMobileAppStats,
    accumulatedMobileAppQRLocations,
  } = usePreloadedQuery(mobileStatsQuery, p.mobileStatsQueryReference)
  const classes = useStyles()

  const filteredViewTimeByTheme = useMemo(
    () =>
      Object.keys(accumulatedMobileAppStats.stats)
        .map((k) => ({
          name: k,
          watched: accumulatedMobileAppStats.stats[k] / 60 / 60,
        }))
        .filter((x) => x.watched && x.name !== 'undefined')
        .sort((a, b) => b.watched - a.watched),
    [],
  )

  const filteredQRLocations = useMemo(
    () =>
      Object.keys(accumulatedMobileAppQRLocations.statsAccumulated)
        .map((k) => ({
          name: k,
          users: accumulatedMobileAppQRLocations.statsAccumulated[k],
        }))
        .filter((x) => x.users && x.name !== 'undefined')
        .sort((a, b) => b.users - a.users),
    [],
  )

  return (
    <div className={classes.page}>
      <Grid container spacing={2}>
        <Grid item xs={12} className="section-not-to-print">
          <div className={classes.item}>
            <p>Total amount of app users</p>
            <h1>{mobileAppUsers.totalAmount}</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.item}>
            <p>Users (per location)</p>
            <ContainerDimensions>
              {({ width }) => (
                <BarChart
                  width={width - 64}
                  data={filteredQRLocations}
                  bars={[
                    {
                      name: 'users',
                      color: '#E09E67',
                    },
                  ]}
                />
              )}
            </ContainerDimensions>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.item}>
            <p>Themes (hours watched)</p>
            <ContainerDimensions>
              {({ width }) => (
                <BarChart
                  width={width - 64}
                  data={filteredViewTimeByTheme}
                  bars={[
                    {
                      name: 'watched',
                      color: '#E09E67',
                    },
                  ]}
                />
              )}
            </ContainerDimensions>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default MobileStatsContainer
