import { Button, CircularProgress } from '@material-ui/core'
import moment from 'moment'

interface SetDateIntervalButtonProps {
  setStartDate: (start: Date | null) => void
  setEndDate: (end: Date | null) => void
  onExportClicked: () => void
  onExportXlsxClicked: () => void
  creatingPdf: boolean
  creatingXlsx: boolean
}

const SetDateIntervalButton = ({
  setStartDate,
  setEndDate,
  onExportClicked,
  onExportXlsxClicked,
  creatingPdf,
  creatingXlsx,
}: SetDateIntervalButtonProps) => {
  const setStartDateToToday = (start: Date) => {
    setStartDate(start)
    setEndDate(new Date())
  }

  return (
    <div>
      {(
        [
          'day',
          'week',
          'month',
          'year',
        ] as moment.unitOfTime.DurationConstructor[]
      ).map((unit) => (
        <Button
          // variant="outlined"
          color="secondary"
          style={{ height: '100%' }}
          onClick={() =>
            setStartDateToToday(
              moment().subtract(1, unit).startOf('day').toDate(),
            )
          }
        >
          1{unit[0]}
        </Button>
      ))}
      <Button
        color="secondary"
        style={{ height: '100%', marginLeft: 16 }}
        onClick={() => {
          setStartDate(null)
          setEndDate(null)
        }}
      >
        Reset date
      </Button>
      <Button
        // variant="outlined"
        color="secondary"
        style={{ height: '100%', marginLeft: 16 }}
        onClick={() => onExportClicked()}
        disabled={creatingPdf}
      >
        {creatingPdf ? (
          <>
            <CircularProgress size={20} />
            <>Exporting PDF</>
          </>
        ) : (
          <>Export PDF</>
        )}
      </Button>
      <Button
        // variant="outlined"
        color="secondary"
        style={{ height: '100%', marginLeft: 16 }}
        onClick={() => onExportXlsxClicked()}
        disabled={creatingXlsx}
      >
        {creatingXlsx ? (
          <>
            <CircularProgress size={20} />
            <>Exporting Excel</>
          </>
        ) : (
          <>Export Excel</>
        )}
      </Button>
    </div>
  )
}

export default SetDateIntervalButton
