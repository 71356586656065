import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import MaterialTable from 'material-table'
import { Suspense, useEffect, useState } from 'react'
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay'
import { ThemeGroup, useApi } from './api'
import { ThemeGroupsQuery } from './__generated__/ThemeGroupsQuery.graphql'
import { ThemeGroupsThemesQuery } from './__generated__/ThemeGroupsThemesQuery.graphql'

import { DialogBox } from 'components'
import { ListSelector } from 'pages/Units/components'
import Fuse from 'fuse.js'

const themeGroupsQuery = graphql`
  query ThemeGroupsQuery {
    themeGroups {
      id
      name
      description
      themes
    }
  }
`

const themesQuery = graphql`
  query ThemeGroupsThemesQuery {
    themes {
      id
      name
      titleObject
      themeType
      descriptionObject
      thumbnailUrl
      updatedAt
      createdAt
      isMediaComplete
      workTitle
    }
  }
`

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'relative',
  },
  table: {
    width: '100%',
    position: 'relative',
    height: 400,
  },
  topBar: {
    margin: theme.spacing(1.5),
    position: 'absolute',
    zIndex: 200,
  },
}))

const ThemeGroupsContainer = () => {
  const [themeGroupsqueryReference, loadThemeGroupsQuery] =
    useQueryLoader<ThemeGroupsQuery>(themeGroupsQuery)
  const [themesQueryReference, loadThemesQuery] =
    useQueryLoader<ThemeGroupsThemesQuery>(themesQuery)

  useEffect(() => {
    loadThemeGroupsQuery({}, { fetchPolicy: 'network-only' })
    loadThemesQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<CircularProgress style={{ margin: 16 }} />}>
      {themeGroupsqueryReference && themesQueryReference && (
        <ThemeGroups
          themesQueryReference={themesQueryReference}
          themeGroupsqueryReference={themeGroupsqueryReference}
        />
      )}
    </Suspense>
  )
}
interface ThemeGroupProps {
  themesQueryReference: PreloadedQuery<ThemeGroupsThemesQuery>
  themeGroupsqueryReference: PreloadedQuery<ThemeGroupsQuery>
}

const ThemeGroups = ({
  themesQueryReference,
  themeGroupsqueryReference,
}: ThemeGroupProps) => {
  const { themeGroups } = usePreloadedQuery(
    themeGroupsQuery,
    themeGroupsqueryReference,
  )
  const { createThemeGroup, deleteThemeGroup, updateThemeGroup } = useApi()
  const { themes } = usePreloadedQuery(themesQuery, themesQueryReference)
  const [searchWord, setSearchWord] = useState('')

  const [themeGroup, setThemeGroup] = useState<ThemeGroup>()

  const { page, topBar } = useStyles()

  const arrayToObject = (a: any) =>
    a.reduce((acc: any, t: any) => ({ ...acc, [t]: true }), {})

  const getActions = () => {
    const actions = []

    if (themeGroup?.id) {
      actions.push({
        onClick: () => {
          if (
            !themeGroup ||
            !confirm(
              'Are you sure you want to delete this subscription package?',
            )
          )
            return
          deleteThemeGroup({ id: themeGroup.id })
          setThemeGroup(undefined)
        },
        title: 'Delete',
      })
    }

    actions.push({
      onClick: () => setThemeGroup(undefined),
      title: 'Cancel',
    })

    if (!themeGroup?.id) {
      actions.push({
        onClick: () => {
          if (!themeGroup) return
          createThemeGroup({
            name: themeGroup.name,
            description: themeGroup.description,
            themes: Object.keys(themeGroup.themes),
          })
          setThemeGroup(undefined)
        },
        title: 'Save',
      })
    } else {
      actions.push({
        onClick: () => {
          if (!themeGroup) return
          updateThemeGroup({
            ...themeGroup,
            themes: Object.keys(themeGroup.themes),
          })
          setThemeGroup(undefined)
        },
        title: 'Update',
      })
    }
    return actions
  }

  const selectedIds = Object.keys(themeGroup?.themes ?? {})

  const options = {
    threshold: 0.5,
    keys: ['id', 'name', 'workTitle', 'themeType'],
  }

  const fuse = new Fuse(themes, options)
  const searchResult = fuse.search(searchWord.trim())
  const filteredThemes = searchWord ? searchResult.map((t) => t.item) : themes
  const withSelectedThemes = [
    ...filteredThemes.filter((t) => !selectedIds.includes(t.id)),
    ...themes.filter((t) => selectedIds.includes(t.id)),
  ].sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div className={page}>
      <div className={topBar}>
        <Button
          onClick={() =>
            setThemeGroup({ name: '', description: '', id: '', themes: {} })
          }
          variant="outlined"
          color="secondary"
        >
          Create new subscription package
        </Button>
      </div>
      {themeGroups && (
        <MaterialTable
          title=""
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Description', field: 'description' },
            {
              title: 'Themes',
              field: 'themes',
              render: ({ themes }) => Object.keys(themes).length,
            },
          ]}
          options={{
            pageSize: 10,
            sorting: true,
            exportButton: true,
            headerStyle: { backgroundColor: 'transparent' },
          }}
          style={{ backgroundColor: 'transparent' }}
          onRowClick={(_, themeGroup) =>
            themeGroup &&
            setThemeGroup({
              name: themeGroup.name,
              description: themeGroup.description,
              id: themeGroup.id,
              themes: arrayToObject(themeGroup.themes),
            })
          }
          data={themeGroups.map((t) => ({ ...t }))}
        />
      )}
      <DialogBox
        open={!!themeGroup}
        title="Subscription Package"
        fields={[
          {
            type: 'textField',
            label: 'Name',
            value: themeGroup?.name,
            onChange: (name: string) =>
              setThemeGroup((prev) => prev && { ...prev, name }),
          },
          {
            type: 'textField',
            label: 'Description',
            value: themeGroup?.description,
            onChange: (description: string) =>
              setThemeGroup((prev) => prev && { ...prev, description }),
          },
          {
            type: 'textField',
            label: 'Search for themes',
            value: searchWord,
            onChange: (searchWord: string) => setSearchWord(searchWord),
          },
          <ListSelector
            allOptions={withSelectedThemes
              .filter((t) => t.isMediaComplete)
              .map((theme) => ({
                value: theme.id,
                label: `${theme.themeType ?? 'No type'}: ${theme.name} ${
                  theme.workTitle && `(${theme.workTitle})`
                }`,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))}
            selected={selectedIds}
            onChange={(selected) =>
              setThemeGroup(
                (prev) =>
                  prev && {
                    ...prev,
                    themes: arrayToObject(selected),
                  },
              )
            }
          />,
        ]}
        actions={getActions()}
      />
    </div>
  )
}

export default ThemeGroupsContainer
