import React, {
  createContext,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import firebase from 'firebase/app'
import { auth } from '../auth/firebase'
import { RelayEnvironmentProvider } from 'react-relay'
import {
  authMiddleware,
  errorMiddleware,
  RelayNetworkLayer,
  urlMiddleware,
} from 'react-relay-network-modern'
import { Environment, RecordSource, Store } from 'relay-runtime'

type State = {
  user: firebase.User | null
  auth: firebase.auth.Auth | null
  signInWithGoogle: () => void
  signOut: () => void
  getAuthToken: () => Promise<string>
}

const defaultValue: State = {
  user: null,
  auth: null,
  signInWithGoogle: () => null,
  signOut: () => null,
  getAuthToken: async () => '',
}

const provider = new firebase.auth.GoogleAuthProvider()

export const AuthContext = createContext<State>(defaultValue)

export const AuthProvider = ({ children }: { children: ReactElement }) => {
  const [user, setUser] = useState<firebase.User | null>(null)

  const signInWithGoogle = () => {
    auth
      .signInWithPopup(provider)
      .then((res) => {
        setUser(res.user?.email?.endsWith('@wavecare.com') ? res.user : null)
      })
      .catch((error) => {
        console.log(error.message)
        setUser(null)
      })
  }

  const signOut = () => {
    auth.signOut().then(
      function () {
        setUser(null)
      },
      (e) => {
        console.log(e)
        setUser(null)
      },
    )
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      setUser(firebaseUser)
    })

    return unsubscribe
  }, [])

  const getAuthToken = (): Promise<string> => {
    if (!user) return Promise.resolve('')
    return new Promise<string>((resolve) => {
      user?.getIdToken().then((token) => {
        resolve(token)
      })
    })
  }
  const store = useMemo(() => new Store(new RecordSource()), [user?.uid])
  const network = useMemo(
    () =>
      new RelayNetworkLayer([
        urlMiddleware({ url: `${process.env.REACT_APP_API_ENDPOINT}/graphql` }),
        authMiddleware({ token: getAuthToken, allowEmptyToken: true }),
        errorMiddleware(),
      ]),
    [user],
  )

  const env = useMemo(
    () =>
      new Environment({
        store,
        network,
      }),
    [store, network],
  )

  return (
    <AuthContext.Provider
      value={{
        user,
        auth,
        signInWithGoogle,
        signOut,
        getAuthToken,
      }}
    >
      <RelayEnvironmentProvider environment={env}>
        {children}
      </RelayEnvironmentProvider>
    </AuthContext.Provider>
  )
}
