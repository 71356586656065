import {
  Button,
  CircularProgress,
  Snackbar,
  makeStyles,
} from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import MaterialTable from 'material-table'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay'
import { CustomerStatistics, useApi } from './api'
import { CustomerStatisticsQuery } from './__generated__/CustomerStatisticsQuery.graphql'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { DialogBox } from 'components'
import { ListSelector } from 'pages/Units/components'
import Fuse from 'fuse.js'

const customerStatisticsQuery = graphql`
  query CustomerStatisticsQuery {
    customerStatistics {
      id
      name
      description
      wavecareBoxIds
      pageViews
    }
    units {
      id
      unitNumber
      name
    }
  }
`

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'relative',
  },
  table: {
    width: '100%',
    position: 'relative',
    height: 400,
  },
  topBar: {
    margin: theme.spacing(1.5),
    position: 'absolute',
    zIndex: 200,
  },
}))

const CustomerStatisticsContainer = () => {
  const [customerStatisticsQueryReference, loadCustomerStatisticsQuery] =
    useQueryLoader<CustomerStatisticsQuery>(customerStatisticsQuery)

  useEffect(() => {
    loadCustomerStatisticsQuery({}, { fetchPolicy: 'network-only' })
  }, [])

  return (
    <Suspense fallback={<CircularProgress style={{ margin: 16 }} />}>
      {customerStatisticsQueryReference && (
        <CustomerStatisticsPage
          customerStatisticsQueryReference={customerStatisticsQueryReference}
        />
      )}
    </Suspense>
  )
}
interface CustomerStatisticsProps {
  customerStatisticsQueryReference: PreloadedQuery<CustomerStatisticsQuery>
}

const CustomerStatisticsPage = ({
  customerStatisticsQueryReference,
}: CustomerStatisticsProps) => {
  const { customerStatistics, units } = usePreloadedQuery(
    customerStatisticsQuery,
    customerStatisticsQueryReference,
  )

  const {
    createCustomerStatistics,
    deleteCustomerStatistics,
    updateCustomerStatistics,
  } = useApi()

  const [tempCustomerStatistics, setTempCustomerStatistics] =
    useState<CustomerStatistics>()
  const [searchWord, setSearchWord] = useState('')
  const [isCopied, setIsCopied] = useState(false)

  const { page, topBar } = useStyles()

  const arrayToObject = (a: any) =>
    a.reduce((acc: any, t: any) => ({ ...acc, [t]: true }), {})

  const getActions = () => {
    const actions = []

    if (tempCustomerStatistics?.id) {
      actions.push({
        onClick: () => {
          if (
            !tempCustomerStatistics ||
            !confirm(
              'Are you sure you want to delete this customer statistics page?',
            )
          )
            return
          deleteCustomerStatistics({ id: tempCustomerStatistics.id })
          setTempCustomerStatistics(undefined)
        },
        title: 'Delete',
      })
    }

    actions.push({
      onClick: () => setTempCustomerStatistics(undefined),
      title: 'Cancel',
    })

    if (!tempCustomerStatistics?.id) {
      actions.push({
        onClick: () => {
          if (!tempCustomerStatistics) return
          createCustomerStatistics({
            name: tempCustomerStatistics.name,
            description: tempCustomerStatistics.description,
            wavecareBoxIds: Object.keys(tempCustomerStatistics.wavecareBoxIds),
          })
          setTempCustomerStatistics(undefined)
        },
        title: 'Save',
      })
    } else {
      actions.push({
        onClick: () => {
          if (!tempCustomerStatistics) return
          updateCustomerStatistics({
            ...tempCustomerStatistics,
            wavecareBoxIds: Object.keys(tempCustomerStatistics.wavecareBoxIds),
          })
          setTempCustomerStatistics(undefined)
        },
        title: 'Update',
      })
    }
    return actions
  }

  const selectedIds = Object.keys(tempCustomerStatistics?.wavecareBoxIds ?? {})

  const options = {
    threshold: 0.5,
    keys: ['id', 'name', 'unitNumber'],
  }

  const fuse = new Fuse(units, options)
  const searchResult = fuse.search(searchWord.trim())
  const filteredUnits = searchWord ? searchResult.map((t) => t.item) : units
  const withSelectedUnits = [
    ...filteredUnits.filter((t) => !selectedIds.includes(t.id)),
    ...units.filter((t) => selectedIds.includes(t.id)),
  ].sort((a, b) => a.name.localeCompare(b.name))

  const link = useCallback(
    (id: string) => `${window.location.host}/stats/${id}`,
    [],
  )

  return (
    <div className={page}>
      <div className={topBar}>
        <Button
          onClick={() =>
            setTempCustomerStatistics({
              name: '',
              description: '',
              id: '',
              wavecareBoxIds: {},
            })
          }
          variant="outlined"
          color="secondary"
        >
          Create new customer statistics page
        </Button>
      </div>
      {customerStatistics && (
        <MaterialTable
          title=""
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Description', field: 'description' },
            {
              title: 'Wavecare Boxes',
              field: 'wavecareBoxIds',
              render: ({ wavecareBoxIds }) =>
                Object.keys(wavecareBoxIds).length,
            },
            {
              title: 'Page views',
              field: 'pageViews',
              render: ({ pageViews }) => pageViews ?? 0,
            },
            {
              title: 'Statistics page URL',
              field: 'id',
              render: ({ id }) => (
                <div style={{ display: 'flex', gap: 8 }}>
                  {link(id)}
                  <Button
                    style={{
                      zIndex: 100,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      navigator.clipboard.writeText(link(id))
                      setIsCopied(true)
                    }}
                    variant="outlined"
                    color="secondary"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </Button>
                </div>
              ),
            },
          ]}
          options={{
            pageSize: 10,
            sorting: true,
            exportButton: true,
            headerStyle: { backgroundColor: 'transparent' },
          }}
          style={{ backgroundColor: 'transparent' }}
          onRowClick={(_, customerStatistics) =>
            customerStatistics &&
            setTempCustomerStatistics({
              name: customerStatistics.name,
              description: customerStatistics.description,
              id: customerStatistics.id,
              wavecareBoxIds: arrayToObject(customerStatistics.wavecareBoxIds),
            })
          }
          data={customerStatistics.map((t) => ({ ...t }))}
        />
      )}
      <DialogBox
        open={!!tempCustomerStatistics}
        title="Customer Statistics"
        fields={[
          {
            type: 'textField',
            label: 'Name (will be visible on page)',
            value: tempCustomerStatistics?.name,
            onChange: (name: string) =>
              setTempCustomerStatistics((prev) => prev && { ...prev, name }),
          },
          {
            type: 'textField',
            label: 'Description (will not be visible on page)',
            value: tempCustomerStatistics?.description,
            onChange: (description: string) =>
              setTempCustomerStatistics(
                (prev) => prev && { ...prev, description },
              ),
          },
          {
            type: 'textField',
            label: 'Search for Wavecare box',
            value: searchWord,
            onChange: (searchWord: string) => setSearchWord(searchWord),
          },
          <ListSelector
            allOptions={withSelectedUnits
              .map((unit) => ({
                value: unit.id,
                label: `${unit.name} - #${unit.unitNumber}`,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))}
            selected={selectedIds}
            onChange={(selected) =>
              setTempCustomerStatistics(
                (prev) =>
                  prev && {
                    ...prev,
                    wavecareBoxIds: arrayToObject(selected),
                  },
              )
            }
          />,
        ]}
        actions={getActions()}
      />
      <Snackbar
        open={isCopied}
        autoHideDuration={6000}
        onClose={() => setIsCopied(false)}
        message="Link copied to clipboard"
      />
    </div>
  )
}

export default CustomerStatisticsContainer
