import { FC, useEffect, useState } from 'react'
import filesize from 'filesize'
import { makeStyles } from '@material-ui/core'
import VideoIcon from '@mui/icons-material/OndemandVideo'
import ImageIcon from '@mui/icons-material/Image'
import SoundIcon from '@mui/icons-material/LibraryMusic'
import SoftlightIcon from '@mui/icons-material/BlurOn'
import EncryptedIcon from '@mui/icons-material/Lock'
import axios from 'axios'
import { useAuth } from 'hooks'

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}))

type StorageItemLite = {
  Key: string
  Size: number
}

type Props = {
  type: 'original' | 'thumbnail' | 'sound' | 'softlight' | 'encrypted'
  storageItem: StorageItemLite | undefined
  fileName: string
  onFinishedUploading?: (key: string) => void
  newlyAdded?: boolean
  onClick?: () => void
}

const ProcessedFileLine: FC<Props> = (p: Props) => {
  const classes = useStyles()
  const [status, setStatus] = useState<{ status: string; isDone: boolean }>()
  const [observedNotDone, setObservedNotDone] = useState(false)
  const [runCount, setRunCount] = useState(0)
  const [didRetry, setDidRetry] = useState(false)
  const [text, setText] = useState<string>()
  const { user } = useAuth()
  const [uploadedStorageItem, setUploadedStorageItem] = useState()

  const Icon = {
    original: <VideoIcon style={{ fontSize: 18 }} />,
    thumbnail: <ImageIcon style={{ fontSize: 18 }} />,
    sound: <SoundIcon style={{ fontSize: 18 }} />,
    softlight: <SoftlightIcon style={{ fontSize: 18 }} />,
    encrypted: <EncryptedIcon style={{ fontSize: 18 }} />,
  } as const

  const item = p.storageItem ?? uploadedStorageItem

  useEffect(() => {
    if (item) {
      setText(item.Key.replace(/.+\//, ''))
      return
    }

    if (status) {
      setText(status.status)
      return
    }

    setText(undefined)
  }, [item, status, observedNotDone])

  useEffect(() => {
    if (status?.isDone && status.status === 'Done.') {
      const timeout = setTimeout(async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/storage/get-file/${
            p.type
          }?filename=${encodeURIComponent(p.fileName)}`,
          {
            headers: {
              Authorization: `Bearer ${await user?.getIdToken()}`,
            },
          },
        )

        setUploadedStorageItem(data)

        if (data.Key) p.onFinishedUploading?.(data.Key)
      }, 2000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [status])

  useEffect(() => {
    if (item || status?.isDone || (!p.newlyAdded && !didRetry)) {
      return
    }

    const timeout = setTimeout(async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/video/status/${
          p.type
        }?filename=${encodeURIComponent(p.fileName)}`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken()}`,
          },
        },
      )
      setStatus(data)
      setObservedNotDone(!data.isDone)
      setRunCount((prev) => prev + 1)
    }, Math.min(runCount * 250 + Math.random() * 500, 30000))

    return () => {
      clearTimeout(timeout)
    }
  }, [item, status, runCount, didRetry])

  // const allowRetry = status?.status?.includes('Fail')
  const allowRetry = false // TODO: to re-enable retry functionality, we need to make the retry endpoint on api proxy to video-processing

  const retryProcessing = async () => {
    await axios.post(
      process.env.REACT_APP_API_ENDPOINT + '/video/retry-processing/' + p.type,
      {
        filename: p.fileName,
      },
      {
        headers: {
          Authorization: `Bearer ${await user?.getIdToken()}`,
        },
      },
    )
    setStatus(undefined)
    setRunCount(0)
    setDidRetry(true)
  }

  return (
    <div className={classes.item} onClick={() => p.onClick && p.onClick()}>
      {Icon[p.type]}
      <p
        style={{
          fontSize: 10,
          opacity: 0.5,
          marginTop: 5,
          color:
            text === 'Done.'
              ? 'lightgreen'
              : text?.includes('Fail')
              ? '#e16f6f'
              : undefined,
        }}
      >
        {item?.Size ? `${filesize(item.Size)}` : ''}
      </p>
    </div>
  )
}

export default ProcessedFileLine
